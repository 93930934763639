import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import amplitude from "amplitude-js";
import { AmplitudeProvider } from "@amplitude/react-amplitude";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main.jsx";
import FAQ from "./FAQ.jsx";
// import Blog from "./Blog.jsx";
import Signup from "./Signup";
import Login from "./Login";
import TobyLinks from "./TobyLinks";
import TermsOfServices from "./TermsOfServices";
import PrivacyPolicy from "./PrivacyPolicy";
import Uninstall from "./Uninstall";
import UninstallThanks from "./UninstallThanks";
import ResetPassword from "./ResetPassword";
import NotFound from "./NotFound";
import VerifyEmail from "./VerifyEmail";
import Plans from "./OrganizationPlans/Plans";
import TobyLinksHowToPage from "./TobyLinksHowToPage";
import { BlogsMock } from "./Blogs";
import { FullBlog } from "./FullBlog";
import Product from "./Product";
import Analytics from "./Analytics";
import { GOOGLE_ANALYTICS_ID } from "../constants";

const App = () => {
  const [posts, setPosts] = useState([]);
  const [initiate, setInitiate] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const location = useLocation();

  const trackPageViews = () => {
    if (window.gtag) {
      window.gtag("config", GOOGLE_ANALYTICS_ID, {
        page_path: location.pathname,
      });
    }
  };

  useEffect(() => {
    trackPageViews();
  }, [location]);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setPrevScrollpos((prevPos) => {
      const isVisible = prevPos > currentScrollPos || currentScrollPos < 50;
      setVisible(isVisible);
      return currentScrollPos;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    axios.get("/allBlog").then(({ data }) => {
      setPosts(data);
      setInitiate(true);
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Analytics />
      {initiate && (
        <AmplitudeProvider
          amplitudeInstance={amplitude.getInstance()}
          apiKey={null}
        >
          <Header visible={visible} />
          <Switch>
            {/* <Redirect
              exact
              from="/blog"
              to={`/blogs/${posts[0].slug}`}
            />
            <Redirect
              exact
              from="/blogs"
              to={`/blogs/${posts[0].slug}`}
            /> */}
            <Route exact path="/blog">
              <BlogsMock posts={posts} />
            </Route>
            <Route path="/blog/:id">
              <FullBlog posts={posts} />
            </Route>

            <Redirect exact from="/privacy" to="/policy" />
            <Route exact path="/">
              <Main posts={posts} />
            </Route>
            <Route path="/product">
              <Product posts={posts} />
            </Route>
            <Route path="/pricing">
              <Plans />
            </Route>
            <Route path="/contact">
              <FAQ />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            {/* <Route path="/blogs/:id">
              <Blog posts={posts} />
            </Route> */}
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/to/:link_name" component={TobyLinks} />
            <Route path="/to" component={TobyLinksHowToPage} />
            <Route path="/terms">
              <TermsOfServices />
            </Route>
            <Route path="/policy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/goodbye">
              <Uninstall />
            </Route>
            <Route path="/goodbye/thanks">
              <UninstallThanks />
            </Route>
            <Route path="/app/reset">
              <ResetPassword />
            </Route>
            <Route path="/verify">
              <VerifyEmail />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
          <Footer />
        </AmplitudeProvider>
      )}
    </>
  );
};

export default App;
