import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {scrollTo} from '../helpers';

const BlogPosts = ({posts, title, counter, setShowContent}) => {
  const [start, setStart] = useState(0);
  const [tab, setTab] = useState(1);
  const isLongIndex = counter === 6;
  const totalLength = Math.ceil((posts.length-1) / counter);

  const iArr = [];
  for (var i = 1; i <= totalLength; i++) {
    iArr.push(i)
  }
  const [indexArr, setIndex] = useState(iArr.slice(0, 3));

  useEffect(() => {
    setTab(1);
    setStart(0)
    setIndex(iArr.slice(0, 3))
  }, [title]);


  const changeTab = num => {
    setTab(num);
    if (num === indexArr[0]) {
      if (iArr.includes(num-1)) {
        setIndex(iArr.slice(num - 2, num - 2 + 3))
      }
    } else if (num === indexArr[2]) {
      if (iArr.includes(num+1)) {
        setIndex(iArr.slice(num + 1 - 3, num + 1))
      }
    }
    const temp = num*counter - counter;
    setStart(temp)
  }

  var postCounter = 0;
  const handleTabChange = (e, direction, int) => {
    switch(direction) {
      case 'prev':
        if (tab !== 1) {
          changeTab(tab - 1);
          scrollTo('blog-index', e);
        }
        break;
      case 'num':
        changeTab(int);
        scrollTo('blog-index', e);
        break;
      case 'next':
        if (tab !== totalLength) {
          changeTab(tab + 1);
          scrollTo('blog-index', e);
        }
        break;
    }
  }
  const renderIndexBar = () => {
    const inputBar = (
      <div className={`g ${isLongIndex ? 'g-col-3' : 'g-col'} g-i-center c--light-grey w--heavy maxW--med Blog-Index`}>
        {isLongIndex && <a onClick={e => handleTabChange(e, 'prev')}>
          <img className="d--inline m--hor-small" src="/img/icons/grey-arrow.svg" alt="grey-arrow"></img>
          <h6 className="d--inline c--dark-grey page-text">Previous Page</h6>
        </a>}
        <span>
          {!isLongIndex && <img className="d--inline m--hor-small" src="/img/icons/grey-arrow.svg" alt="grey-arrow" onClick={e => handleTabChange(e, 'prev')}></img>}
          {indexArr.map((int,index) => (
            <h6 key={index} className={`d--inline ${tab === int ? 'selected' : ''}`} onClick={e => handleTabChange(e, 'num', int)}>{int}</h6>
          ))}
          {!isLongIndex && <img className="d--inline m--hor-small rotate-180" src="/img/icons/grey-arrow.svg" alt="grey-arrow" onClick={e => handleTabChange(e, 'next')}></img>}
        </span>
        {isLongIndex && <a onClick={e => handleTabChange(e, 'next')}>
          {isLongIndex && <h6 className="d--inline c--dark-grey page-text">Next Page</h6>}
          <img className="d--inline m--hor-small rotate-180" src="/img/icons/grey-arrow.svg" alt="grey-arrow"></img>
        </a>}
      </div>
    )
    return inputBar;
  }

  return (
    <div id="blog-index" className={`bg--light--pink ${isLongIndex ? 'p--top-xl' : 'p--top-med'} p--bot-med p--hor pos--rel`}>
      {isLongIndex && <div className="Shadow-gradient pos--abs-top m--hor-op o-6"></div>}
      <div className="g g-row g-i-center maxW--med">
        {title && <h4 className="t-center text-neutral-800">{title}</h4>}
        {!isLongIndex && <div className="Shadow-gradient--dark o-0 m--ver-med"></div>}
        <div className='g g-gap-3 g-col-autofit-400'>
          {posts.map((post, index) => {
            const {isBgPreviewCentered, cover, title, subtitle, publishDate, slug, isBgY65} = post;
            let imgStyle = 'bgimg bgimg-x-center bgimg-y-center';
            if (isBgPreviewCentered) {
              imgStyle = 'bgimg bgimg-x-center bgimg-y-10'
            }
            if (index >= start && postCounter < counter) {
              postCounter++;
              return (
                <Link key={index} to={`/blogs/${slug}`} className="Blog-Card" key={index} onClick={e => {setShowContent(false); scrollTo('app', e)}}>
                  <div className={imgStyle} style={{'backgroundImage': `url('${cover}')`}}></div>
                  <div className="o--hidden p--all">
                    <h6 className="c--brand">{title}</h6>
                    <p className="c--dark-grey w--bold m--top-sm">{subtitle}</p>
                  </div>
                  <div className="g g-col g-a-i-center g-j-c-between pos--rel Blog-Action p--hor p--top-small p--bot"> 
                    <span className="c--dark-grey w--bold">{publishDate}</span>
                    <img alt="pink-arrow" src="/img/icons/pink-arrow.svg"></img>
                  </div>
                </Link>
              )
            }
          })}
        </div>
        {renderIndexBar()}
      </div>
    </div>
  )
}

BlogPosts.defaultProps = {
  setShowContent: () => {}
};


export default BlogPosts;
