import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import { getAuthToken, setAuthToken } from "./Auth";
import Loader from "./Loader";
import Login from "./Login";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useConsumeToken = () => {
  const [_token, setToken] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const token = query.get("token") || getAuthToken();
    if (token) setToken(token);
  }, [query]);

  return _token;
};

const TobyLinks = ({ match }) => {
  const linkName = match.params.link_name;
  const token = useConsumeToken();
  const displayLogin = !token;

  const windowTokenRef = useRef({ updated: false });
  const [isLoading, setIsLoading] = useState(false);
  const [requirePassword, setRequirePassword] = useState(false);
  const [error, setError] = useState("");

  const redirectTobyLink = async (inputPassword = "", token = "") => {
    setIsLoading(true);
    TobyLinkStore.set(linkName);

    try {
      let res = await fetch("/tobylinks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          link: linkName,
          token: token,
          password: inputPassword,
        }),
      });

      if (!res.ok) {
        // Link found but it's password-protected
        if (res.status === 403) {
          setRequirePassword(true);
          setIsLoading(false);
          return res;
        }
      }

      let data = await res.json();
      if (data.error) {
        setIsLoading(false);
        setError(data.error);
        return;
      }

      TobyLinkStore.remove();
      if (data.urls.length == 1) {
        window.location.href = data.urls[0];
        return;
      }

      for (let i = 1; i < data.urls.length; i++) {
        window.open(data.urls[i], "_blank");
      }
      window.location.href = data.urls[0];
    } catch (error) {}

    setIsLoading(false);
  };

  useEffect(() => {
    if (!token) return;
    redirectTobyLink(null, token);
  }, [token]);

  useEffect(() => {
    const eventListener = (event) => {
      if (event.source !== window) return;
      if (event.data.type) {
        if (event.data.type === "TOBY_TOKEN_UPDATED") {
          windowTokenRef.current = { updated: true };
          const tokenFromExtension = event.data.token;
          if (tokenFromExtension) {
            setAuthToken(tokenFromExtension);
            redirectTobyLink(null, tokenFromExtension);
          }
        }
      }
    };

    window.addEventListener("message", eventListener);
  }, [linkName]);

  if (displayLogin) {
    return <Login onLogin={(data) => redirectTobyLink(null, data.token)} />;
  }

  return (
    <Amplitude
      eventProperties={{
        scope: ["tobylinks"],
      }}
    >
      <LogOnMount eventType="tobylinks" />
      <div className="Create split">
        <div className={`split-pane`}>
          <div className="split-content">
            <div className="split-contentWrapper">
              <div className="Create-maxWidth">
                {requirePassword && (
                  <TobyLinkWithPassword
                    redirectTobyLink={redirectTobyLink}
                    linkName={linkName}
                    isLoading={isLoading}
                  />
                )}
                {error && <h4 align="center">Link "{linkName}" not found.</h4>}
                {isLoading && (
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Amplitude>
  );
};

const TobyLinkStore = {
  key: "cur_toby_link",
  set: (link_name) => localStorage.setItem(self.key, link_name),
  get: (link_name) => localStorage.getItem(self.key, link_name),
  remove: () => localStorage.removeItem(self.key),
};

export default TobyLinks;

const TobyLinkWithPassword = ({ redirectTobyLink, linkName, isLoading }) => {
  const [inputPassword, setInputPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    let res = await redirectTobyLink(inputPassword);
    if (res?.status == 403) {
      setError("Password is invalid");
    }
  };

  return (
    <>
      <h2 className="Create-title">Enter password</h2>
      <div className="Create-subtitle">
        Enter the password to access the Toby Link "{linkName}"
      </div>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Password:</p>
          <input
            type="password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            required
            placeholder="********"
            autoComplete="new-password"
            className="js-validate-required js-validate-email js-trigger Form-input Form-input--dark"
          />
        </label>
        {error && (
          <div className="Create-error">
            <p className="Create-errorLabel" style={{ display: "" }}>
              {error}
            </p>
          </div>
        )}
        {!isLoading && (
          <div className="Create-nextButton">
            <button
              className="js-trigger-target Button Button--secondary Button--large"
              type="submit"
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        )}
      </form>
    </>
  );
};
