import React, { useState, Fragment, useEffect } from "react";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { TOBY_AMPLITUDE_KEY } from "../constants";

const uninstallReasons = [
  {
    title: "I didn't like Toby on the new tab",
    placeholder: "Install Toby Mini",
    isInstallTobyMiniDisplayed: true,
  },
  {
    title: "It's too expensive",
    placeholder: "What would you consider a fair price?",
  },
  {
    title: "It's not useful to me",
    placeholder: "How can we make Toby more useful?",
  },
  {
    title: "It slows down my computer",
    placeholder: "What in particular felt slow?",
  },
  {
    title: "There were missing features",
    placeholder: "What features are missing?",
  },
  {
    title: "I use another similar extension",
    placeholder: "Which one?",
  },
  {
    title: "There was a bug",
    placeholder: "Tell us more about the bug?",
  },
  {
    title: "Other",
    placeholder: "Tell us more",
  },
];
const Uninstall = () => {
  const [reason, setReason] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleCheckBox = (e) => {
    const _reason = e.target.getAttribute("value");
    if (_reason === reason) {
      setReason("");
    } else {
      setReason(_reason);
    }
    setMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reason == "") return;

    setIsLoading(true);
    axios
      .post("/surveys/response", {
        email,
        reason,
        message,
        userId,
      })
      .finally(() => history.push("/goodbye/thanks"));
  };

  useEffect(() => {
    try {
      const qs = new URLSearchParams(location.search);
      const token = qs.get("t");
      const uid = token ? atob(token) : null;

      amplitude.getInstance().init(TOBY_AMPLITUDE_KEY);

      if (uid) {
        setUserId(uid);
        amplitude.getInstance().setUserId(uid);
      } else {
        amplitude.getInstance().setUserId("unknown_user");
      }

      amplitude.getInstance().logEvent("Uninstall");
    } catch (error) {
      console.error("Error logging uninstall event", error);
    }
  }, []);

  return (
    <div className="Header-Margin--offset">
      <div className="Shadow-gradient o-4"></div>
      <Amplitude
        eventProperties={{
          scope: ["Uninstall"],
        }}
      >
        <LogOnMount eventType="Uninstall" />
        <div className="g g-gap-8 g-j-i-center g-col-autofit-500 p--hor p--ver-lg maxW--med uninstall">
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="g g-row-min form g-gap-8"
          >
            <div className="g g-gap-1--5 g-min-width-30">
              <h6 className="w--bold-x c--brand">
                Why did you uninstall Toby?
              </h6>
              {uninstallReasons.map(
                ({ title, placeholder, isInstallTobyMiniDisplayed }, index) => (
                  <Fragment key={index}>
                    <div className="g g-col-30 g-a-i-center">
                      <a
                        onClick={handleCheckBox}
                        value={title}
                        className={`checkbox ${
                          title === reason ? "checkbox--fill" : ""
                        }`}
                      ></a>
                      <span className="c--light-black">{title}</span>
                    </div>
                    {isInstallTobyMiniDisplayed && title === reason && (
                      <div className="c--white bg--brand p--hor-sm p--ver toby-mini">
                        <a
                          href="https://chrome.google.com/webstore/detail/toby-mini/gfdcgfhkelkdmglklfbndgopaihmoeci?hl=en"
                          target="_blank"
                          rel="noopener"
                          className="c--white t--underline w--bold-x"
                        >
                          Install Toby Mini.
                        </a>
                        <div className="c--white">
                          Same Toby, but <em>not</em> on the new tab!
                        </div>
                      </div>
                    )}
                    {!isInstallTobyMiniDisplayed && title === reason && (
                      <textarea
                        onChange={(e) => setMessage(e.target.value)}
                        className="g-col-span-all"
                        placeholder={placeholder}
                      />
                    )}
                  </Fragment>
                )
              )}
            </div>
            <div className="g g-row-min g-gap-1 m--top-auto">
              <h6 className="w--bold-x c--brand">
                Want us to get back to you? (optional)
              </h6>
              <input
                placeholder="Your email"
                onChange={(e) => setEmail(e.target.value)}
                className="text-field"
              />
              <button
                type="submit"
                disabled={isLoading || (!isLoading && reason == "")}
                style={{
                  opacity: isLoading || (!isLoading && reason == "") ? 0.5 : 1,
                }}
              >
                Send feedback
              </button>
            </div>
          </form>
          <div className="g g-gap-2 p--ver p--hor-med goodbye">
            <div className="testimonial"></div>
            <p className="c--light-black w--medium">
              Hi 👋,
              <br />
              <br />
              I’m Summer, the product designer on Toby. I’m sorry to see you go,
              but I’d love to learn from your experience with Toby.
              <br />
              <br />
              Whether you are working solo or collaborating with a team, we want
              to make Toby the best tool to declutter your browser and help you
              find focus in your digital life.
              <br />
              <br />
              We know Toby isn’t perfect, but we want to make it better for you!
              You can help by sharing why you uninstalled it. We really value
              your feedback.
              <br />
              <br />
              Thank you,
              <br />
              <br />
              Summer
              <br />
              Product Designer
              <br />
              <br />
            </p>
          </div>
        </div>
      </Amplitude>
    </div>
  );
};

export default Uninstall;
