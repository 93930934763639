import React from "react";
import { Link, useLocation } from "react-router-dom";
import { scrollTo, getBrowserCTA } from "../helpers";
import { isMobile } from "../constants";
const { href } = getBrowserCTA();

const Header = ({ visible }) => {
  const location = useLocation();
  return (
    <div className="flex justify-center">
      <div className={`Header ${!visible ? "Header--hidden" : ""}`}>
        <div className="Header-container">
          <div className="Header-logo">
            <Link onClick={(e) => scrollTo("app", e)} to="/" className="Logo">
              Toby
            </Link>
          </div>
          <div className="Menu w--bold">
            <ul className="align--center flex--center">
              {/* <li className="Menu-item font-normal">
              <Link onClick={(e) => scrollTo("app", e)} to="/product">
                Product
              </Link>
            </li> */}
              <li className="Menu-item font-normal hover:underline">
                <Link onClick={(e) => scrollTo("app", e)} to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="Menu-item font-normal hover:underline">
                <a
                  href="https://help.gettoby.com/support/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help Center
                </a>
              </li>
              <li className={"Menu-item font-normal hover:underline"}>
                <a
                  style={{
                    color: location.pathname.includes("blog") ? "#F65077" : "",
                    fontWeight: location.pathname.includes("blog")
                      ? "bold"
                      : "",
                  }}
                  href="/blog"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          {!isMobile && (
            <a
              className="js-Button--install hide--small rounded border border-brand-300 bg-transparent px-5 py-1 text-sm font-semibold uppercase transition-colors hover:bg-brand-300 hover:text-white"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              Install Toby
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
