import React, {useState} from 'react';
import FAQCard from './FAQCard';
import axios from 'axios';
import {scrollTo} from '../helpers'
import {
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";

const headers = {
  'accept': 'application/json',
  'content-type': 'application/json'
}

const FrequentlyAskedQuestions = [
  {
    title: 'What is Toby?',
    description: "Toby is better than bookmarks, it's a browser extension that helps you organize your tabs on every new page. Available on <a href=https://chrome.google.com/webstore/detail/toby-for-chrome/hddnkoipeenegfoeaoibdmnaalmgkpip target=blank>Chrome</a>, and soon Firefox and Microsoft Edge."
  },
  {
    title: 'How does Toby work?',
    description: "The Toby browser extension lives on every new tab page. This was designed purposely to help surface relevant information to you throughout the day. To use Toby, simply create a collection and drag any open tabs from the right into a collection - it will close the tab for you. As is Toby stores your tabs locally on your computer but we recommend creating a Toby account in order to activate sync and a ton of extra features like sharing. A Toby account also gives us the ability to provide restoration services on lost collections and tabs (within reason)."
  },
  {
    title: 'Who uses Toby?',
    description: "Toby is both a personal productivity and team collaboration tool. Teams that maximize the benefits of Toby the most are usually large and use a ton of tools across different departments. Toby’s strength is being able to speed dial the user into workflows or into important assets. Instead of using the search function on various tools (including email and chat) Toby helps navigate these different tools based on the tabs you’ve added. This also applies to users who use Toby for personal productivity, it’s the best way to access and retrieve content immediately."
  },
  {
    title: 'What are the benefits of using Toby?',
    description: "A recent survey to Toby users has shown that we can save you up to 8 hours a week. If you think about the time it takes you to find a document or the file your coworker sent you 2 months ago, you can see how it can quickly add up. Toby is an entirely new way for modern teams to access resources and share knowledge seamlessly. It will save you a significant amount of time and money and even better allow you to do more important work."
  },
  {
    title: 'How do I create a Toby account?',
    description: "There are 2 ways to create a Toby account. Sign up on the website and then install the extension or if you’ve already installed the extension from the Chrome store you can sign up within the app. If you choose to skip the account creation process you can still continue to use Toby, however all of your information will be stored locally. Meaning you won’t be able to access your tabs on a different computer."
  },
  {
    title: 'How do I create a Toby organization?',
    description: 'Organizations unlocks collaboration and sharing for the entire team. To create one - simply hover beside "My Collection" and create give a name to your organization.'
  },
  {
    title: 'How many tags can I have?',
    description: "Tags are a great way to organize your collections. There are many uses some of which include to-dos, clients, projects, and more. The maximum number of tags you can have are 8."
  },
  {
    title: 'How do I edit or delete tags?',
    description: "In the drop down menu select the tag you would like to delete or edit. Hover your mouse over the tag and on the left side you’ll see “EDIT TAG”."
  },
  {
    title: 'I’ve noticed a section for “My Collections” is there a way I can create different sections for work?',
    description: "Yes, this feature is now availible to all users. You can create an organization and seperate teams so you can share resources and knowledge all from the new tab page."
  },
  {
    title: 'How do I re-order my collections?',
    description: 'Every collection has a couple of different menu options such as “Open x tabs” or “Share” the “<>” allows you to drag the collections up and down.'
  },
  {
    title: 'Can Toby run on a mobile phone? Or a tablet?',
    description: "We are working on an iOS reader app so that you can access your tabs on the go. Mobile is super important to us and we think you’ll find many new uses with the Toby app. Toby works great on touch screen laptops but not on tablets yet."
  },
  {
    title: 'How do I submit feature requests?',
    description: "<p>We love new feature requests! Send us an email at <a href=mailto:hello@gettoby.com>hello@gettoby.com</a> or reach out on <a href=https://twitter.com/TobyForTabs target=blank)>Twitter.</a></p>"
  },
  {
    title: 'Toby disappeared is no longer working, what should I do?',
    description: "<ol><li>Go to chrome://extensions (or Menu, Settings, Extensions)</li><li>Check if Toby is showing up in the list and is enabled. If it's not enabled, enable it by checking the Enable checkbox</li><li>If opening a new tab still doesn't load Toby, try unchecking it and reenabling it by checking Enable again</li><li>If Toby still isn't loading, it may be getting overrun by another extension. Try unchecking other extensions and trying new tabs until Momentum launches</li><li>Check your Chrome browser to see if it is up to date and the same with Toby</li></ol>"
  },
  {
    title: 'How do I manually update Toby?',
    description: "<p>Sometimes when we push out a new update you won't get it right away. To ensure that your version of Toby is up to date follow these three easy steps:</p><ol><li>Type: chrome://extensions/ into the Google Chrome URL bar</li><li>Click the \"Developer Mode\" checkbox, located near the top right of the page</li><li>Click on the \"Update extensions now\" button that appears</li></ol>"
  },
  {
    title: 'Help! I lost my Bookmarks Bar',
    description: "<p>Have no fear, your previously saved bookmarks are just around the corner!</p><ol><li>If you're on a computer running Windows press: Ctrl + Shift + B on your keyboard at the same time</li><li>If you're on a Mac press: Command + Shift + B on your keyboard at the same time</li></ol>"
  },
  {
    title: 'I’ve requested a password reset, but I haven’t received an email from Toby yet',
    description: "<ol><li>If you sent yourself the email but never received it: Check your spam folder (and any email filters you've set up) to make sure the message isn't being directed away from your email inbox</li><li>If you cannot find the password reset email after checking your spam folder, try adding <a href=hello@gettoby.com>hello@gettoby.com</a> to your contact list. Afterward, we recommend sending yourself a password reset email again</li></ol>"
  },
  {
    title: 'I received the email to reset my password but I get an error message when I try to enter a new password',
    description: "<ol><li>Make sure to use the email address tied to your Toby account.</li><li>Type your email address in lower-case during this process.</li><li>The reset password link you will receive by email is only valid for 60 minutes.</li><li>Check this step-by-step <a href=https://docs.google.com/document/d/1gB2DQgIZiIO0Nz7KPrPJOARA76_PdoNs1MRgfX9dXH4/edit#heading=h.gkddzyqf88sv> guide.</a></li></ol>"
  },
  {
    title: 'Tabs, Notes, Collections, Tags, Teams, Organizations: Definitions',
    description: "<ol><li>Tabs - same as your browser tabs</li><li>Notes - a text file created inside of a collection(s)</li><li>Collections - a group of tabs or notes inside of Toby</li><li>Tags - a label attached to a collection(s) to help sort and filter tabs/notes</li><li>Teams - a group of people who own shared collections</li><li>Organizations - groups of teams and individuals that own shared collections and non-shared collections</li></ol>"
  },
  {
    title: 'How do I delete my account?',
    description: 'Click on the account menu at the top right corner of Toby, then "Edit Account" and finally "Sign out and delete my account"'
  }
]

const FAQ = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);
  const validateRegex = (text, re) => {
    return text.match(re) !== null;
  };

  let isEmail = false;
  let isName = false;
  let isMessage = false;

  const handleContact = e => {
    isEmail = validateRegex(email, /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    isName = name.length > 0;
    isMessage = message.length > 0;
    if (isEmail && isName && isMessage) {
      const body = {
        name,
        email,
        message
      }
      axios.post('/contact/support', body, headers)
        .then(() => setIsSent(true))
        .catch(() => setIsSent(false));
      e.preventDefault();
    }
  }
  const url = window.location.hash.substring(1)

  return (
    <div className="Header-Margin--offset">
      <div className="Shadow-gradient o-4"></div>
      <Amplitude
        eventProperties={{
          scope: ['faq']
        }}>
        {url === 'support' ? <LogOnMount eventType="support"/> : <LogOnMount eventType="faq"/>}
        <div className="g g-j-i-center g-gap-8 maxW--med p--hor p--bot-xl p--top-med">
          <div className="align--center">
            <h4>Frequently Asked Questions</h4>
          </div>
          <div className="g g-col g-gap-6">
          <div className="g g-gap-2">
          {FrequentlyAskedQuestions.map((question, index) => (
            <FAQCard key={index} question={question} index={index} key={index}/>
          ))}
          </div>
          <div className="hide--small m--top">
            <h6 className="w--bold align--center p--bot-sm w--bold-med">Need Help?</h6>
            <button onClick={() => scrollTo('support')} className="Button Button--tertiary Button--padding-sm">Contact Support</button>
          </div>
          </div>
          </div>
          <div id="support" className="g g-i-center g-gap-1 bg--light-grey p--ver-lg t-center FAQ">
            {isSent ? (
              <h4 className="c--brand">Your Message was Sent!</h4>
            ) : (
              <>
                <h4>Questions, Feedback, Support</h4>
                <h6 className="w--bold c--grey">We'll be happy to hear from you</h6>
                <form className="g g-row g-j-i-center g-gap-1 Contact-form m--top">
                  <input type='text' value={name} name="name" placeholder="Enter your Name" onChange={e => setName(e.target.value)} required/>
                  <input type='email' value={email} name="email" placeholder="Enter your email address" onChange={e => setEmail(e.target.value)} required/>
                  <textarea value={message} name="message" placeholder="Write us a message" onChange={e => setMessage(e.target.value)} required/>
                  <button className="Button w--bold color-text-light-blue m--top" onClick={handleContact}>Send Message</button>
                  <p className="m--top-med">Alternatively you can email us at <a href='mailto:hello@gettoby.com'>hello@gettoby.com</a></p>
                </form>
              </>
            )}
        </div>
      </Amplitude>
    </div>
  )
};


export default FAQ;