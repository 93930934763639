import React, { useEffect, useState } from "react";
import { FeatureBlogCard, BlogCard } from "./BlogCards";
import { Link } from "react-router-dom";

export const BlogsMock = ({ posts }) => {
  const [numBlogsShown, setNumBlogsShown] = useState(10);
  const [blogsShown, setBlogsShown] = useState([]);

  const showBlogsSubset = () => {
    const blogSubset = posts.filter(
      (post) => posts.indexOf(post) < numBlogsShown
    );
    setBlogsShown(
      blogSubset.map((post, i) => {
        if (i > 0 && i < numBlogsShown) {
          const bgColorIdx = i < 10 ? i : i % 10;
          return (
            <BlogCard
              key={`${post.title}-${i}`}
              blogIdx={bgColorIdx}
              post={post}
            />
          );
        }
      })
    );
  };

  useEffect(() => {
    showBlogsSubset();
  }, [numBlogsShown]);

  return (
    <div className="flex justify-center">
      <div className="Header-Margin--offset flex max-w-[1500px] flex-col items-center justify-center gap-5">
        <div className="mt-12 flex w-full flex-col justify-center gap-12  lg:h-[31.5rem] lg:flex-row lg:gap-0">
          <div className="flex flex-grow flex-col justify-center lg:items-center">
            <div className="flex flex-col gap-5 px-8 ">
              <h2 className="text-4xl font-semibold text-gray-800">
                Whats up with Toby?
              </h2>
              <h6 className="text-xl font-normal text-gray-800">
                Keep up with our new features and announcements
              </h6>
            </div>
          </div>

          <div className="flex flex-grow items-center justify-center px-6 md:px-0 lg:pr-6">
            <Link
              className="flex h-full items-center"
              to={`/blog/${posts[0].slug}`}
            >
              <FeatureBlogCard post={posts[0]} />
            </Link>

            <img
              src="../img/product/small-dots.svg"
              alt="Dots"
              className="absolute right-[540px] top-[220px] z-[-1] hidden xl:block"
            />
            <img
              src="../img/product/medium-dots.svg"
              alt="Dots"
              className="absolute right-[-65px] top-[220px] z-[-1] hidden xl:block"
            />
          </div>
        </div>

        <div className="mb-12 mt-5 grid grid-cols-1 gap-x-12 gap-y-16 bg-offwhite px-8 pt-12 md:grid-cols-2 md:pt-24 xl:grid-cols-3">
          {blogsShown}
        </div>
        {blogsShown.length < posts.length && (
          <button
            onClick={() => setNumBlogsShown((prevState) => prevState + 9)}
            className="flex w-36 items-center justify-center px-2.5 py-1 "
          >
            <p className="text-sm font-semibold text-pink-500">VIEW MORE</p>
          </button>
        )}
      </div>
    </div>
  );
};
