import React from 'react';

const SignupRole = ["Administrative/Facilities", "Accounting/Finance", "Business Development", "Business Owner", "Customer Support", "Data/Analytics/Business Development", "Design", "Engineering (Software)", "Marketing", "Media/Communications", "Operations", "Product Management", "Program/Project Management", "Research", "Sales", "Other"];
const SignupIsManager = ["No", "Yes"];
const SignupUse = ["Work", "Personal", "School", "Other"];
const SignupUserFrom = ["Product Hunt", "Chrome store", "Twitter", "Facebook", "YouTube", "Blog post on another website", "From a coworker", "From a friend", "Other"];

const SignupUserInfo = ({handleSubmit}) => (
  <div id="createUserInfo" className="Create-userInfo Create-flowBlock">
    <form className="Create-maxWidth" onSubmit={handleSubmit}>
      <div>
        <p className="Create-inputLabel">How did you hear about Toby?</p>
        <div className="Form-selectWrapper">
          <select className="Create-userFrom Form-input Form-input--dark">
            <option value='' disabled selected>Select one</option>
            {SignupUserFrom.map((name, index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <p className="Create-inputLabel">What is your role?</p>
        <div className="Form-selectWrapper">
          <select className="Create-companyRole Form-input Form-input--dark">
            <option value='' disabled selected>Select one</option>
            {SignupRole.map((name,index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <p className="Create-inputLabel">Are you a manager? (Do people report to you?)</p>
        <div className="Form-selectWrapper">
          <select className="Create-userManager Form-input Form-input--dark">
            {SignupIsManager.map((name,index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <p className="Create-inputLabel">What will you use Toby for?</p>
        <div className="Form-selectWrapper">
          <select className="Create-userUse Form-input Form-input--dark">
            {SignupUse.map((name, index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="Create-nextButton">
        <button className="Button Button--secondary Button--large" onClick={handleSubmit}>Next</button>
      </div>
    </form>
  </div>
)

export default SignupUserInfo;