import React, { useState } from 'react';
import Hero from './Hero';
import ProductFeature from './ProductFeature';
import BlogPosts from './BlogPosts';
import Banner from './Banner';
import {
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";
import {isMobile} from '../constants';
import {getBrowserCTA} from '../helpers';

const {browser, href} = getBrowserCTA()

const FeaturesSwitch = [
  {
    title: 'Tabs',
    text: 'Drag your browser tabs into a collection to bring context into your workflow. Find what you need instantly by using visual search.'
  },
  {
    title: 'Collections',
    text: 'Organize your team\'s resources in collections. Make a collection for a project, a topic, a team, or anything—everyone invited to shared collection can speed dial into the tabs you\'ve curated.'
  },
  {
    title: 'Teams',
    text: 'Join a team to access the collective knowledge of your organization. Onboard new employees by inviting them into different project teams.'
  }
]

const Product = ({posts}) => {
  const [selectedFeature, setSelectedFeature] = useState(0);
  return (
    <div className="Header-Margin--offset">
      <Amplitude
        eventProperties={{
          scope: ["product"]
        }}>
        <LogOnMount eventType="product"/>
        <div className="Header--noShadow">
          <Banner isProduct/>
          <Hero heading='Workspace in your Browser' showButton/>
        </div>
        <ProductFeature count={5}/>
        <div className="bg--dark-lightBlack c--dark-grey p--ver-med g g-row g-gap-2">
          <h4 className="align--center p--hor c--white">Amazing things that can be done with Toby</h4>
          <div className="Shadow-gradient--dark o-2"></div>
          <div className="FeaturedSwitcher-features">
            <img alt="feature" src={`/img/product/Feature__S_0${selectedFeature+1}.svg`} className="FeaturedSwitcher-features--image"/>
            <div className="FeaturedSwitcher-switches">
              <div className="FeaturedSwitcher-switchesContainer p--hor g g-row g-gap-1">
                {FeaturesSwitch.map(({title, text}, index) => (
                  <div key={index} onClick={() => setSelectedFeature(index)} className={`g g-row g-gap-1 p--hor-sm p--ver FeaturedSwitcher-switch${selectedFeature === index ? (' FeaturedSwitcher-switch--active') : ('')}`}>
                    <h6>{title}</h6>
                    <h6 className="w--normal">{text}</h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="Happy m--wide bg--brand">
            <div className="Happy-circle"></div>
            <div className="g g-i-center g-gap-2 align--center">
              <h2 className="c--white z--index-1">Tame Your Browser Tabs</h2>
              <h5 className="c--white t--dimed">Be happy and download toby now!</h5>
              <a className="Button Button--light Button--large Button--pinkShadow Button--chrome js-Button--installChrome" href={href} target="_blank" rel="noopener">
                <span className="Button--chromeWrapper">
                  <img alt="chrome-icon" className="Button--chromeIcon" src="/img/icons/chrome.svg"></img>
                  <span className="hide--small js-Button--installChromeText">Add Toby to {browser}</span>
                  <span className="hide--large">Available on Chrome</span>
                </span>
              </a>
            </div>
          </div>
        )}
        <BlogPosts posts={posts} title ="See what's new with us!" counter={2} />
      </Amplitude>
    </div>
  )
}

export default Product