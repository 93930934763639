import React from "react";
import { Link } from "react-router-dom";

export const FeatureBlogCard = ({ post }) => {
  const getImgContainerStyle = (post) => {
    switch (true) {
      case post.alignBottom:
        return "flex flex-grow items-end justify-center";
      default:
        return "flex flex-grow items-center justify-center";
    }
  };

  const getImgStyle = (post) => {
    switch (true) {
      case post.fullHeight:
        return "h-[100%]";
      case post.fillCard:
        return "h-[100%] w-[100%]";
      default:
        return "max-w-[55%] rounded";
    }
  };
  const containerStyle = getImgContainerStyle(post);
  const imgStyle = getImgStyle(post);

  return (
    <div
      className="flex h-full max-h-[425px] max-w-[575px] flex-col items-center justify-end overflow-hidden rounded shadow-md"
      style={{ backgroundColor: post.bgColor }}
    >
      <div className={containerStyle}>
        <img className={imgStyle} src={post.cover} alt="Blog Image" />
      </div>
      <div className="flex w-full flex-col gap-3 bg-offwhite p-5">
        <h6 className="truncate text-lg font-semibold text-neutral-800">
          {post.title}
        </h6>
        <p className="line-clamp-2 text-sm font-normal text-neutral-800">
          {post.subtitle}
        </p>
        <div className="flex w-full justify-between">
          <p className="text-xs font-light text-neutral-800">Toby Team</p>
          <p className="text-xs font-light text-neutral-800">
            {post.publishDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export const BlogCard = ({ post }) => {
  const getImgStyle = (post) => {
    switch (true) {
      case post.autoSize !== undefined:
        return {
          display: "flex",
          height: "auto",
          borderRadius: "4px",
          paddingLeft: "12px",
          paddingRight: "12px",
        };
      case post.fillCard !== undefined:
        return {
          display: "flex",
          height: "200px",
          width: "400px",
          borderRadius: "4px",
        };
      case post.scale !== undefined:
        return {
          display: "flex",
          height: "185px",
          borderRadius: "4px",
          paddingLeft: "12px",
          paddingRight: "12px",
          transform: "scale(1.5)",
        };
      default:
        return {
          display: "flex",
          height: "185px",
          borderRadius: "4px",
          paddingLeft: "12px",
          paddingRight: "12px",
        };
    }
  };
  const getImgContainerStyle = (post) => {
    switch (true) {
      case post.alignBottom:
        return "flex h-[200px] w-full items-end justify-center rounded";
      default:
        return "flex h-[200px] w-full items-center justify-center rounded";
    }
  };
  const containerStyle = getImgContainerStyle(post);
  const coverStyle = getImgStyle(post);

  return (
    <Link to={`/blog/${post.slug}`}>
      <div className="flex flex-col rounded shadow-md sm:w-[360px] md:h-[350px] md:w-[360px]">
        <div
          className={containerStyle}
          style={{
            backgroundColor: post.bgColor,
          }}
        >
          <img style={coverStyle} src={post.cover} alt="Blog Image" />
        </div>
        <div className="flex h-[150px] w-full flex-col justify-between rounded bg-offwhite p-5">
          <div className="flex flex-col gap-3">
            <h6 className="truncate text-lg font-semibold text-neutral-800">
              {post.title}
            </h6>
            <p className="line-clamp-2 text-sm font-normal text-neutral-800">
              {post.subtitle}
            </p>
          </div>
          <div className="flex w-full justify-between">
            <p className="text-xs font-light text-neutral-800">Toby Team</p>
            <p className="text-xs font-light text-neutral-800">
              {post.publishDate}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
