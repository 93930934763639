import { twMerge } from 'tailwind-merge';
import {isMobile} from '../constants';
import clsx from 'clsx';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

const WebFont = require('webfontloader');
window.WebFont = WebFont;

const installLink = 'https://chrome.google.com/webstore/detail/hddnkoipeenegfoeaoibdmnaalmgkpip';

// https://stackoverflow.com/a/13348618
const isChrome = () => {
  const isChromium = window.chrome,
    winNav = window.navigator,
    vendorName = winNav.vendor,
    isOpera = winNav.userAgent.indexOf('OPR') > -1,
    isIEedge = winNav.userAgent.indexOf('Edge') > -1;

    return isChromium !== null && isChromium !== undefined && vendorName === 'Google Inc.' && isOpera == false && isIEedge == false;
};

const closeInstall = () => {
  document.body.classList.remove('Body--installing');
};

export const installExtension = () => {
  if(isChrome() && chrome && chrome.webstore && chrome.webstore.install) {
    document.body.classList.add('Body--installing');
    chrome.webstore.install(installLink, () => {
      ga('send', 'event', 'Extension', 'install', 'Installed successfully');
      closeInstall();
    }, () => {
      ga('send', 'event', 'Extension', 'installCancel', 'Install popup cancelled');
      closeInstall();


    });
  } else {
    window.open(installLink);
  }
};

export const installExtensionSignup = () => {
  document.querySelector('.Create-install').classList.remove('Create-install--error');
  if(isChrome() && chrome && chrome.webstore && chrome.webstore.install) {
    chrome.webstore.install(installLink, () => {
      ga('send', 'event', 'Extension', 'install', 'Installed successfully');
    }, () => {
      ga('send', 'event', 'Extension', 'installCancel', 'Install popup cancelled');
      document.querySelector('.Create-install').classList.add('Create-install--error');
    });
  } else {
    window.open(installLink);
  }
};

export const scrollTo = (id, e) => {
  if (e && (e.metaKey || e.shiftKey)) {
    return;
  }
  if (isMobile) {
    document.getElementById(id).scrollIntoView()
  } else {
    setTimeout(() => document.getElementById(id).scrollIntoView(), 100)
  }
}
const getBrowser = () => {
  // Opera 8.0+
  // var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';
  if (isFirefox) {
    return 'Firefox'
  }

  // Safari 3.0+ "[object HTMLElementConstructor]" 
  // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  // if (isSafari) {
  //   return 'Safari'
  // }

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;
  if (isIE) {
    return 'Internet Explorer'
  }

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  if (isEdge) {
    return 'Edge'
  }
  
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Edge (based on chromium) detection
  var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
  if (isEdgeChromium) {
    return 'Edge'
  }

  // Blink engine detection
  // var isBlink = (isChrome || isOpera) && !!window.CSS;
  // if (isBlink) {
  //   return 'Blink'
  // }

  // Chrome 1 - 79
  // if (isChrome) {
  //   return 'Chrome'
  // }

  // if (isOpera) {
  //   return 'Opera'
  // }

  return 'Chrome'
}

export const getBrowserCTA = () => {

  const browser = getBrowser()

  const href = browser === 'Firefox' ? 'https://addons.mozilla.org/af/firefox/addon/toby-for-tabs/' : "https://chrome.google.com/webstore/detail/toby-for-chrome/hddnkoipeenegfoeaoibdmnaalmgkpip?hl=en"

  return {
    browser,
    href
  }
}

export const sanitizeEmail = email => {
  const sanitized = email.toLowerCase();
  return sanitized;
};

export function getLocal(key) {
  return localStorage.getItem(key);
}

export function setLocal(key, value) {
  localStorage.setItem(key, value);
}

export function removeLocal(key) {
  localStorage.removeItem(key);
}

export function arrayToObject(keys) {
  return keys.reduce((obj, key) => {
    obj[key] = true;
    return obj;
  }, {});
}
