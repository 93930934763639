module.exports = function() {
  'use strict';

  /**
   * @name envs
   * @description Env configurations.
   * @type {Object}
   */
  const envs = {
    development: {
      env: 'development',
      title: 'Better Than Bookmarks - Toby (development)',
      apiUrl2: 'https://api-staging2.gettoby.com/v2',
      apiUrl3: 'https://api-staging2.gettoby.com/v3',
      websiteUrl: 'https://www.gettoby.com',
      freshDeskUrl: 'https://gettoby.freshdesk.com',
      freshDeskAPIToken: 'iLjmzrZTIYiFlmU7mO',
      AMPLITUDE_KEY: 'f872554163a2a9987c6cabb0d6355d3e',
      TOBY_AMPLITUDE_KEY: '5970734ea54ee7b5936d736d061dc0cd',
      goodbyeSurveyID: '657acf35-b823-4f01-8168-261c96cc7524',
      GOOGLE_ANALYTICS_ID: '',
    },
    staging: {
      env: 'staging',
      title: 'Better Than Bookmarks - Toby (staging)',
      apiUrl2: 'https://api-staging2.gettoby.com/v2',
      apiUrl3: 'https://api-staging2.gettoby.com/v3',
      websiteUrl: 'https://www.gettoby.com',
      freshDeskUrl: 'https://gettoby.freshdesk.com',
      freshDeskAPIToken: 'iLjmzrZTIYiFlmU7mO',
      AMPLITUDE_KEY: 'f872554163a2a9987c6cabb0d6355d3e',
      TOBY_AMPLITUDE_KEY: '5970734ea54ee7b5936d736d061dc0cd',
      goodbyeSurveyID: '657acf35-b823-4f01-8168-261c96cc7524',
      GOOGLE_ANALYTICS_ID: '',
    },
    production: {
      env: 'production',
      title: 'Better Than Bookmarks - Toby',
      apiUrl2: 'https://api2.gettoby.com/v2',
      apiUrl3: 'https://api2.gettoby.com/v3',
      websiteUrl: 'https://www.gettoby.com',
      freshDeskUrl: 'https://gettoby.freshdesk.com',
      freshDeskAPIToken: 'iLjmzrZTIYiFlmU7mO',
      AMPLITUDE_KEY: '32a5f6bdbf95fe9608b13c639aae8b89',
      TOBY_AMPLITUDE_KEY: '51b299c93d6fd42d7762e8b2e8aab32c',
      goodbyeSurveyID: 'db029097-6d55-4825-975a-626f6e670aaf',
      GOOGLE_ANALYTICS_ID: 'G-WFH1MGXMEV',
    }
  };

  console.log(process.env.NODE_ENV);

  return envs[process.env.NODE_ENV];
};
