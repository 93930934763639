import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { GetStarted } from "../Main";
import { UsedByLogos } from "../Marquee";
import { PlanComparisonTable } from "./PlanComparisonTable";
import { FAQ } from "./FAQ";
import { Button } from "../core/Button";
import { PlanCard } from "./PlanCard";

// single dot as an svg
// convert it into data url to use as background image
// so we can tile it using css and make it easier to render
const lightDot = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E %3Ccircle cx='15.75015' cy='15.72866' r='2.64859' fill='%23DDDDF5'/%3E%3C/svg%3E")`;

const Toggle = ({
  isOn,
  setIsOn,
}: {
  isOn: boolean;
  setIsOn: (isOn: boolean) => void;
}) => {
  const toggleSwitch = () => setIsOn(!isOn);

  return (
    <div
      className={clsx(
        "flex w-11 cursor-pointer rounded-2xl p-0.5",
        isOn ? "bg-brand-300" : "bg-[#B7B7CE]",
        isOn ? "justify-end" : "justify-start"
      )}
      onClick={toggleSwitch}
    >
      <div className="h-[18px] w-[18px] rounded-[9px] bg-white shadow-[0px_2px_4px_rgba(0,_35,_11,_0.20)]" />
    </div>
  );
};

export type Plan = {
  accessRole: string;
  desc: string;
  features: string[];
  id: string;
  legacyYearlyDiscount: string;
  legacyYearlyPrice: number;
  monthlyPrice: number;
  monthlyPriceId: string;
  name: string;
  yearlyDiscount: string;
  yearlyPrice: number;
  yearlyPriceId: string;
};

export type PlanFeatures = {
  name: string;
  type?: "header";
  starter?: {
    content?: string;
    available: boolean;
  };
  productivity?: {
    content?: string;
    available: boolean;
  };
  team?: {
    content?: string;
    available: boolean;
  };
  link?: string;
};

export type FeatureFlag = {
  flag: string;
  payload?:
    | {
        plans: Plan[];
      }
    | {
        features: PlanFeatures[];
      };
  status: string;
};

export type PlanData = {
  flags: FeatureFlag[];
};

export const processPlansData = (data: PlanData) => {
  let plans: Plan[] = [];
  let plansComparison: PlanFeatures[] = [];

  data.flags.forEach((flag) => {
    // gets latest plans
    if (
      flag.flag.includes("plans") &&
      flag.payload &&
      typeof flag.payload === "object" &&
      "plans" in flag.payload
    ) {
      plans = flag.payload.plans as Plan[];
    }
    if (
      flag.flag.includes("planTable") &&
      flag.payload &&
      typeof flag.payload === "object" &&
      "features" in flag.payload
    ) {
      plansComparison = flag.payload.features ?? [];
    }
  });

  return {
    plans,
    plansComparison,
  };
};

const Plans = () => {
  const [isYearly, setIsYearly] = useState(true);

  const [loading, setLoading] = useState(true);
  const [{ plans, plansComparison }, setPlans] = useState<
    ReturnType<typeof processPlansData>
  >({
    plans: [],
    plansComparison: [],
  });
  const [error, setError] = useState<string | null>(null);

  const [disableStudentDiscount, setDisableStudentDiscount] = useState(false);

  const productivityDiscount = plans
    .find((plan) => plan.id === "ProductivityPlan")
    ?.yearlyDiscount?.replace("%", "");

  const teamDiscount = plans
    .find((plan) => plan.id === "TeamPlan")
    ?.yearlyDiscount?.replace("%", "");

  const loadPlans = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await fetch("https://capi.gettoby.com/v1/flags/eval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          flags: ["plans", "planTable", "cfgBase"],
          input: {
            env: process.env.NODE_ENV === "staging" ? "staging" : undefined,
          },
        }),
      });
      if (resp.ok) {
        const data = await resp.json();
        try {
          setPlans(processPlansData(data));
          if (
            data.flags.find((config) => config.flag.includes("cfgBase"))
              ?.payload?.disableStudentDiscount
          ) {
            setDisableStudentDiscount(true);
          }
        } catch (e) {
          setError("Something went wrong.");
          console.log("Error processing plans plans", e);
        }
      } else {
        setError("Something went wrong.");
        console.log("Error fetching plans", resp);
      }
      setLoading(false);
    } catch (error) {
      setError("Something went wrong.");
      console.log("Error fetching plans", error);
    }
  }, []);

  useEffect(() => {
    loadPlans();
  }, []);

  // ----------------------------------------
  // Return
  // ----------------------------------------

  if (error) {
    return (
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-y-12">
        <h3 className="text-2xl font-semibold text-neutral-800">{error}</h3>
        <Button
          variant="secondary"
          size="lg"
          className="w-auto"
          onClick={() => {
            setError(null);
            loadPlans();
          }}
        >
          Try again
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="Header-Margin--offset relative isolate flex flex-col items-center">
        <h1 className="mb-3 mt-6 text-center text-4xl font-semibold text-neutral-800">
          Find your perfect plan
        </h1>
        <p className="mx-4 mb-16 text-center text-xl font-normal text-neutral-800">
          Find the perfect plan for you or your company, starting at $4.50 a
          month. <br />
          Cancel anytime.
        </p>
        <div className="relative mb-16 flex items-center gap-2">
          <span className="text-lg font-semibold text-neutral-800">
            Monthly
          </span>
          <Toggle isOn={isYearly} setIsOn={setIsYearly} />
          <span className="text-lg font-semibold text-neutral-800">Yearly</span>
          {productivityDiscount && teamDiscount && (
            <span className="absolute left-[105%] whitespace-nowrap rounded-[10px] bg-[#DDFFEF] p-1.5 text-center text-xs font-semibold uppercase text-success-300">
              Save{" "}
              {Math.min(parseInt(productivityDiscount), parseInt(teamDiscount))}
              -
              {Math.max(parseInt(productivityDiscount), parseInt(teamDiscount))}
              %
            </span>
          )}
        </div>
        <div className="container isolate mx-auto grid grid-cols-1 justify-center gap-4 px-8 sm:px-0 lg:grid-cols-[repeat(3,_minmax(0,_350px))]">
          {(plans.length > 0 ? plans : Array(3).fill({})).map((plan, index) => (
            <PlanCard
              key={index}
              {...plan}
              isYearly={isYearly}
              isLoading={loading}
            />
          ))}
          <div
            className="absolute bottom-[84px] left-[-200px] right-[-200px] -z-[1] h-[52%] bg-[#FAFAFA] bg-center bg-repeat bg-blend-normal"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(250, 250, 250, 1), rgba(250, 250, 250, 0)), ${lightDot}`,
            }}
          />
        </div>
        <div className="z-[1] mt-14 flex flex-col items-center justify-center gap-y-4 px-4 text-center text-xl font-medium text-neutral-800">
          <p>
            Enterprise?{" "}
            <a
              href="mailto:support@gettoby.com?subject=Enterprise%20Plan%20Inquiry"
              className="font-semibold text-neutral-800 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
          </p>
          {!disableStudentDiscount && (
            <p>
              Are you a student or educator?{" "}
              <a
                href="https://help.gettoby.com/support/tickets/new?ticket_form=student_and_educator_verification"
                className="block font-semibold text-neutral-800 underline md:inline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get a discount
              </a>
            </p>
          )}
        </div>
      </div>
      <div className="UsedBy-companies maxW--med scale-75">
        {UsedByLogos.map((company, index) => (
          <div
            key={index}
            className="flex flex-[1_0_33%] justify-center px-4 py-8 md:flex-[1_0_20%]"
          >
            <img
              alt={company.alt}
              src={company.src}
              className={clsx("max-h-16", company.className)}
            />
          </div>
        ))}
      </div>
      <PlanComparisonTable
        className="mt-7 md:mt-[104px]"
        plans={plans}
        features={plansComparison}
        isYearly={isYearly}
        isLoading={loading}
      />
      <img
        className="absolute left-0 top-[270%] -z-[1] opacity-40"
        src="../img/product/blob-shape-3.svg"
        alt="Shape"
      />
      <img
        className="absolute left-[15%] top-[270%] -z-[1] opacity-10"
        src="../img/product/blob-shape-2.svg"
        alt="Shape"
      />
      <FAQ className="my-[104px] px-4" />
      <GetStarted />
    </>
  );
};

export default Plans;
