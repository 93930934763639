import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

export const FullBlog = ({ posts }) => {
  const slug = useParams();
  const selectedBlog = posts.find((post) => post.slug === slug.id);
  const cleanContent = DOMPurify.sanitize(selectedBlog.content);

  const inlineStyle = {
    backgroundColor: selectedBlog.bgColor,
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  const getImgStyle = (blog) => {
    switch (true) {
      case blog.fillCard:
        return "h-[100%] w-[100%]";
      case blog.fullHeight:
        return "h-[100%]";
      default:
        return `max-h-[350px] ${blog.scale && "scale-150"} `;
    }
  };
  const imgStyle = getImgStyle(selectedBlog);

  return (
    <div className="flex justify-center">
      <div className="Header-Margin--offset w-full max-w-[1500px]">
        <Link className="mx-4 mb-2 flex gap-1 md:mx-12" to={"/blog"}>
          <img src="../img/icons/arrow-back.svg" alt="back arrow" />
          <p className="text-sm font-semibold text-brand-300">BACK</p>
        </Link>
        <div className="flex justify-center">
          <div className="mx-6 mt-6 flex w-[750px] flex-col items-start gap-y-3">
            <h3 className="text-4xl font-semibold text-neutral-800 ">
              {selectedBlog.title}
            </h3>
            <p className=" text-xs font-light text-neutral-800 ">{`Toby Team | ${selectedBlog.publishDate}`}</p>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <div
            className={`md: my-8 flex min-h-[260px] w-[750px] md:h-96 ${
              selectedBlog.alignBottom ? "items-end" : "items-center"
            } justify-center`}
            style={inlineStyle}
          >
            <img
              className={imgStyle}
              src={selectedBlog.cover}
              alt="Blog Image"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div
            className="Blog-Content mx-6 flex max-w-[750px] justify-start gap-y-6 "
            dangerouslySetInnerHTML={{ __html: cleanContent }}
          />
          {selectedBlog.video && (
            <div className="mx-6 h-[500px] w-[750px]">
              <iframe
                src={selectedBlog.video}
                allowFullScreen
                title={selectedBlog.title}
                style={{
                  marginTop: "32px",
                  width: "100%",
                  height: "450px",
                  borderRadius: "5px",
                }}
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
