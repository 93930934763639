import React from 'react';
import { Helmet } from 'react-helmet';
import { GOOGLE_ANALYTICS_ID } from "../constants";

const Analytics = () => (
  <Helmet>
    <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${GOOGLE_ANALYTICS_ID}');
      `}
    </script>
  </Helmet>
);

export default Analytics;
