import { useRef } from 'react';

const useOnMount = (callback) => {
  const hasMounted = useRef(false);

  if (typeof callback === 'function' && !hasMounted.current) {
    callback();
    hasMounted.current = true;
  }
};

export default useOnMount;
