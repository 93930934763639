import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useCbOnce from './useCbOnce';
import SignupEmail from './SignupEmail';
import SignupName from './SignupName';
import SignupPassword from './SignupPassword';
import SignupCompany from './SignupCompany';
import SignupCompanyInfo from './SignupCompanyInfo';
import SignupUserInfo from './SignupUserInfo';
import SignupInvite from './SignupInvite';
import SignupInstall from './SignupInstall';
import axios from 'axios';
import {
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";
import { sanitizeEmail } from '../helpers';

const headers = {
  'accept': 'application/json',
  'content-type': 'application/json'
}

const Signup = () => {
  const body = document.getElementsByTagName('body')[0];
  useEffect(() => {
    body.setAttribute('style', 'zoom: 1;');
    return () => body.removeAttribute('style')
  })

  const [initial, setInitial] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [currentBlock, setCurrentBlock] = useState(0);
  const [currentUser, setCurrentUser] = useState('')
  const [title, setTitle] = useState('Create your account');
  const [isValid, setIsValid] = useState(true);
  const [blocks, setBlocks] = useState([]);

  // If Creating Profile Fails
  const [errorMessage, setErrorMessage] = useState('');
  const [createError, setCreateError] = useState(false);
  const [after, setAfter] = useState(false);
  
  // Initial Setup
  useEffect(() => {
    setBlocks(Array.from(document.querySelectorAll('.Create-flowBlock')));
    setInitial(true);
  }, [])

  // Shifting Blocks
  const setToBlock = () => {
    blocks.forEach((b) => {
      return (
        b.style.transform =
          `translateX(calc(${currentBlock * -100}% - ${100 * currentBlock}vw))`
        )
    });
  };

  // Autofocus currentBlock
  const setBlockFocus = (waitForTransition = false) => {
    const block = blocks[currentBlock];
    const eventHandler = e => {
      if (e.propertyName === 'transform') focus();
    };
    const focus = () => {
      block.removeEventListener('transitionend', eventHandler);
      const focusEl = block.querySelector('input') || block.querySelector('select');
      if (focusEl) focusEl.focus();
    };
    if (waitForTransition) block.addEventListener('transitionend', eventHandler);
    else focus();
  };

  // Moving Block / Set Title
  const moveBlock = () => {
    const titles = {
      0: "Create your account",
      1: "Create your account",
      2: "Create your account",
      3: 'About your company',
      4: 'About your team',
      5: 'Let us know more',
      6: 'Unlock dark theme',
      7: ''
    }
    setToBlock(currentBlock);
    setTitle(titles[currentBlock])
    setBlockFocus(true);
  };

  // After first render / Whenever currentBlock is updated ---> ShiftsBlock
  useEffect(() => {
    if (initial) {
      setIsValid(true);
      moveBlock();
    }
  }, [currentBlock])

  // Skip Blocks
  const skipBlocks = (num) => {
    let tempBlocks = blocks.slice();
    let tempCurrentBlock = currentBlock;
    for (let i=0; i < num-1; i++) {
      tempBlocks[tempCurrentBlock + 1].style.opacity = '0';
      tempCurrentBlock++;
    }
    setBlocks(tempBlocks);
    setCurrentBlock(currentBlock + num);
  };

  const validateRegex = (text, re) => {
    return text.match(re) !== null;
  };

  // Submit in each block
  const handleSubmit = (type) => {
    let temp;
    switch (type) {
      case 'email' :
        return useCbOnce(() => {
          setAfter(createError)
          temp = validateRegex(email, /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
          if (temp) {
            setCurrentBlock(currentBlock + 1); 
            return true
          } else {
            setIsValid(temp)
          }
        }, createError);
      case 'name' :
        return useCbOnce(() => {
          temp = validateRegex(name, /^[^\s]+(\s[^\s]+)+$/);
          if (temp) {
            setCurrentBlock(currentBlock + 1); 
            return true
          } else {
            setIsValid(temp)
          }
        }, createError);
      case 'password' :
        return useCbOnce(() => {
          temp = validateRegex(password, /^.{8,}$/);
          if (temp) {
            createAccount();
            return true
          } else {
            setIsValid(temp)
          }
        }, after);
      case 'company' :
        return useCbOnce(() => {
          temp = company.length > 0;
          if (temp) {
            setCurrentBlock(currentBlock + 1)
            return true
          } else {
            setIsValid(temp)
          }
        });
      case 'skip1' :
        return useCbOnce(() => {
          skipBlocks(2);
          return true;
        });
      case 'skip6' :
        return useCbOnce(() => {
          skipBlocks(7);
          return true;
        });
      case 'invite' :
        return useCbOnce(() => {
          inviteFriends();
          return true;
        });
      default :
        return useCbOnce(() => {
          setCurrentBlock(currentBlock + 1);
          return true;
        });
    }
  }

  // Creating User

  const userKey = '__toby__user__';
  const invitationKey = '__toby__invited__';
  const saveUser = user => localStorage.setItem(userKey, JSON.stringify(user));
  const saveInvitation = friends => localStorage.setItem(invitationKey, friends);

  const inputValue = name => {
    const input = document.querySelector(`input[name="${name}"]`);
    return input ? input.value : null;
  };

  const getLoginInfo = () => {
    const security = inputValue('__toby_security__');
    if (security) return null;
    if (!email || !name || !password) return null;
    return {
      email: sanitizeEmail(email),
      name,
      password
    };
  };

  const createAccount = () => {
    const user = getLoginInfo();
    if (!user) return;
    axios.post('/users', user, headers)
      .then(({data}) => {
        if (data && data.error) {
          setErrorMessage(data.error);
          setCurrentBlock(0);
          setCreateError(!createError);
          return;
        }
        setCurrentUser(user);
        saveUser(user);
        setCurrentBlock(currentBlock + 1);
      })
      .catch(err => console.error(err))
  };
  

  // Invite Friends
  const addOneEmailInvite = () => {
    const container = document.querySelector('.Create-inviteEmails');
    const all = [...document.querySelectorAll('.Create-inviteSection')];
    if (all.length < 5) {
      const last = all.pop();
      const newItem = last.cloneNode(true);
      container.appendChild(newItem);
    }
  };

  const inviteFriends = () => {
    const extractInviteData = section => ({
      email: section.querySelector('.Create-inviteEmail input').value,
      name: section.querySelector('.Create-inviteName input').value
    });

    const friends = Array.from(document.querySelectorAll('.Create-inviteSection'))
      .map(extractInviteData)
      .filter(data => data && data.email !== '');
      
    friends.forEach(friend => {
      let body = {
        from: currentUser.email,
        name: friend.name,
        invite: friend.email
      }
      axios.post('/invite', body, headers)
        .then(res => {
          if (!res.error) {
            console.log(`Invitation sent successfully to ${friend.email}`);
          }
        })
    });
    saveInvitation(friends);
    setCurrentBlock(currentBlock+1)
    return false;
  };
  
  return (
    <Amplitude
      eventProperties={{
        scope: ['signup']
      }}>
      <LogOnMount eventType="signup"/>
      <div className="Create split">
       <div className={`split-pane ${currentBlock===6 ? 'split-paneLarge' : ''}`}>
         <div className="split-content">
           <Link className="Logo" to="/">Toby</Link>
           <div className="split-contentWrapper">
             <div className="Create-maxWidth">
               <h2 className="Create-title">{title}</h2>
             </div>
             <div className="Create-flowBlocks">
               <SignupEmail errorMessage={errorMessage} email={email} setEmail={setEmail} handleSubmitSkip6={handleSubmit('skip6')} handleSubmit={handleSubmit('email')} isValid={isValid} selected={currentBlock === 0}/>
               <SignupName name={name} setName={setName} handleSubmit={handleSubmit('name')} isValid={isValid}/>
               <SignupPassword password={password} setPassword={setPassword} handleSubmit={handleSubmit('password')} isValid={isValid}/>
               <SignupCompany company={company} setCompany={setCompany} handleSubmitSkip1={handleSubmit('skip1')} handleSubmit={handleSubmit('company')} isValid={isValid}/>
               <SignupCompanyInfo handleSubmit={handleSubmit('skip1')}/>
               <SignupUserInfo handleSubmit={handleSubmit()}/>
               <SignupInvite addOneEmailInvite={addOneEmailInvite} handleSkip={handleSubmit()} handleSubmit={handleSubmit('invite')}/>
               <SignupInstall bool={currentBlock===7}/>
             </div>
           </div>
         </div>
       </div>
       <div className={`Create-graphics split-pane ${currentBlock===6 ? 'split-paneSmall' : ''}`}>
         <img alt="signup" src='/img/signup/theme.png'/>
       </div>
      </div>
    </Amplitude>
  )
}

export default Signup;