import { useEffect, useState } from 'react';

const useCbOnce = (cb, dep) => {
  const [called, setCalled] = useState(false);

  useEffect(() => {
    setCalled(false)
  }, [dep])

  return e => {
    e.preventDefault()
    if (!called) {
      if (cb()) {
        setCalled(true)
      }
    }
  }
}

export default useCbOnce;