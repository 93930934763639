import React from 'react';
import {Link} from 'react-router-dom';
import {scrollTo} from '../helpers';

const getProductFeatures = (showReadMoreButtons) => [
  {
    description: 
    <div className="direction-ltr">
      <h5 className="text-neutral-800">Your team’s working memory, all in one place</h5><br/><br/>
      <h6 className="text-neutral-800 w--medbold">Centralize your team’s resources/tools by adding them as tabs into a Toby collection<br/><br/>Organize your collections with any web app page, library, and tool configured endlessly</h6><br/><br/>
      {showReadMoreButtons && <Link to="/product" onClick={e => scrollTo('app', e)} className="Button Button--tertiary">Read More &mdash;></Link>}
    </div>,
    image: <img alt="product-feature" src='img/product/toolbox.png'/>
  },
  {
    description: 
    <div className="direction-ltr">
      <h5 className="text-neutral-800">Give your workflow special powers</h5><br/>
      <h6 className="text-neutral-800 w--medbold">Context switch on the fly by swapping collections from different projects.<br/><br/>Bring clarity into   your work by assigning tags to your collections.</h6><br/><br/>
      {showReadMoreButtons && <Link to="/product" onClick={e => scrollTo('app', e)} className="Button Button--tertiary">Read More &mdash;></Link>}
    </div>,
    image: <img alt="product-feature" src='img/product/context.png'/>
  },
  {
    description: 
    <div className="direction-ltr">
      <h5 className="text-neutral-800">Make shared knowledge work for you</h5><br/>
      <h6 className="text-neutral-800 w--medbold">Find what you need within seconds using Toby search.<br/><br/>Say goodbye to lost sticky notes, long   chat threads, and dust-collecting bookmarks.</h6>
    </div>,
    image: <img alt="product-feature" src='img/product/search.png'/>
  },
  {
    description: 
    <div className="direction-ltr">
      <h5 className="text-neutral-800">From “Hired” to “Team member” within hours</h5><br/>
      <h6 className="text-neutral-800 w--medbold">Scale onboarding like never before by sharing relevant project collections on day one.<br/><br/>Solve the information transfer gap by surfacing content with a snap of a finger.</h6>
    </div>,
    image: <img alt="product-feature" src='img/product/onboarding.png'/>
  },
  {
    description: 
    <div className="direction-ltr">
      <h5 className="text-neutral-800">Seamless team collaboration like it should be</h5><br/>
      <h6 className="text-neutral-800 w--medbold">Talk about work, where work happens by using Toby notes.<br/><br/>Remove tool apathy, Toby empowers   teams to make informed and relevant project decisions.</h6>
    </div>,
    image: <img alt="product-feature" src='img/product/idea.png'/>
  }
];

const ProductFeature = ({count, showReadMoreButtons}) => {
  const features = getProductFeatures(showReadMoreButtons).slice(0, count)
  return (
    <>
      {features.map(({description, image}, index) => {
        const paddingTop = !index ? 'p--top-xl' : 'p--top-lg';
        return (
          <div key={index} className="Feature--background">
            {!index && <div className="Shadow-gradient o-2"></div>}
            <div className={`g g-j-i-center g-col-autofit-400 g-gap-4 maxW--med p--bot-lg ${paddingTop} p--hor Feature--container`}>
              {description}
              {image}
            </div>
          </div>
        )
      })}
    </>    
  )
}

export default ProductFeature;