import React from 'react';

const SignupCompany = ({company, setCompany, handleSubmitSkip1, handleSubmit, isValid, selected}) => (
  <div id="createCompany" className="Create-company Create-flowBlock">
    <form className="Create-maxWidth" onSubmit={handleSubmit}>
      <div className={isValid ? '' : 'Create-error'}>
        <p>What's the name of your organization?</p>
        <input autoFocus={selected ? 'autofocus' : ''} value={company} onChange={e => setCompany(e.target.value)} className="js-validate-required js-trigger Create-companyName Form-input Form-input--dark" type="text" name="ORG" placeholder="Company Name"/>
      </div>
      <div className="Create-nextButton">
        <button className="js-trigger-target Button Button--secondary Button--large" onClick={handleSubmit}>Next</button>
        <a className="Create-link" onClick={handleSubmitSkip1}>I'm not in a company</a>
      </div>
    </form>
  </div>
)

export default SignupCompany;