import React from "react";
import clsx from "clsx";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../core/Accordion";

const Collapse = (props: {
  className?: string;
  title: string;
  body: string | React.ReactNode;
  value: string;
}) => {
  const { className, value } = props;

  return (
    <AccordionItem value={value} className={clsx("py-6", className)}>
      <AccordionTrigger className="text-left text-xl font-semibold text-neutral-800 hover:no-underline">
        {props.title}
      </AccordionTrigger>
      <AccordionContent className="pr-8 text-lg text-neutral-800">
        {props.body}
      </AccordionContent>
    </AccordionItem>
  );
};

export const FAQ = (props: { className: string }) => {
  const { className } = props;
  return (
    <div className={clsx("container mx-auto flex flex-col", className)}>
      <h2 className="mb-8 text-5xl font-semibold text-neutral-800">
        Frequently asked questions
      </h2>
      <Accordion type="single" collapsible className="flex w-full flex-col">
        <Collapse
          value="1"
          title="Can I keep using Toby for free?"
          body={
            <>
              <span>
                Yes! We know that trying a new tool can take some time to
                incorporate into your workflow. Our Starter plan allows you to
                try Toby at your own pace!
              </span>
              <br />
              <br />
              <span>
                The Starter plan allows access to basic features, including a
                limited number of saved tabs and unlimited members. If you need
                more advanced features like unlimited tabs and priority support,
                you can upgrade to one of our paid plans.
              </span>
            </>
          }
        />
        <Collapse
          value="2"
          title="What are the plan prices?"
          body={
            <>
              <span className="text-lg text-neutral-800">
                Toby offers three plans:
              </span>
              <ol className="mt-2" style={{ listStyle: "decimal inside" }}>
                <li className="my-4 pl-4">
                  <span className="text-base font-medium">
                    Starter Plan (Free)
                  </span>
                  <ul className="list-none pl-8 text-base">
                    <li className="my-1 text-lg">Save up to 60 tabs</li>
                    <li className="my-1 text-lg">Unlimited members</li>
                    <li className="my-1 text-lg">
                      Basic tab management features
                    </li>
                  </ul>
                </li>
                <li className="my-4 pl-4">
                  <span className="text-base font-medium">
                    Productivity Plan ($6/month or $4.50/month billed yearly)
                  </span>
                  <ul className="list-none pl-8 text-base">
                    <li className="my-1 text-lg">Unlimited saved tabs</li>
                    <li className="my-1 text-lg">Unlimited members</li>
                    <li className="my-1 text-lg">
                      Advanced search and deduplication
                    </li>
                    <li className="my-1 text-lg">All Starter Plan features</li>
                  </ul>
                </li>
                <li className="my-4 pl-4">
                  <span className="text-base font-medium">
                    Team Plan ($10/month or $8/month billed yearly)
                  </span>
                  <ul className="list-none pl-8 text-base">
                    <li className="my-1 text-lg">Unlimited saved tabs</li>
                    <li className="my-1 text-lg">Unlimited members</li>
                    <li className="my-1 text-lg">
                      SSO (Single Sign-On), priority support, centralized
                      billing
                    </li>
                    <li className="my-1 text-lg">
                      All Productivity Plan features
                    </li>
                  </ul>
                </li>
              </ol>
            </>
          }
        />

        <Collapse
          value="3"
          title="How can I pay for a Toby subscription?"
          body="Payments are processed through Stripe, and we accept all major credit cards and Visa debit cards. You can choose between monthly and yearly billing, with the yearly plan offering a great discount!"
        />
        <Collapse
          value="4"
          title="What happens when I add or remove a member from my Productivity or Team plan?"
          body={
            <>
              <span>
                When you add a member to your Organization, you will be charged
                for each member added based on your plan's pricing. If you add
                users mid billing cycle, you will be charged a prorated rate.
              </span>
              <br />
              <br />
              <span>
                When you remove a member, you won’t be charged for the removed
                member going forward. Your billing will adjust accordingly in
                the next billing cycle. If a member is removed mid billing
                cycle, you’ll receive a credit for the unused portion of their
                subscription.
              </span>
            </>
          }
        />
        <Collapse
          value="5"
          title="Can I change or cancel my subscription?"
          body="Yes! You can change or cancel your subscription at any time. If you’ve paid for a yearly plan and decide to cancel, your subscription will remain active until the end of your current paid period."
        />
        <Collapse
          value="6"
          title="What is Toby’s refund policy?"
          body="If you choose to cancel your Toby subscription, you won’t be charged for the next billing period. Your subscription will remain active until the end of your current paid period, whether monthly or yearly, but no further charges will be applied after cancellation."
        />
      </Accordion>
    </div>
  );
};
