import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import { authenticateUser } from "./Auth";
import Loader from "./Loader";

const Login = ({ nextPage, onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let data = await authenticateUser({ email, password });
      if (data.error) {
        setError("Invalid email or password.");
        setIsLoading(false);
        return;
      }

      if (nextPage) {
        history.push(location.state.nextPage);
        return;
      }

      if (location.state?.nextPage) {
        history.push(location.state.nextPage);
        return;
      }

      if (onLogin) onLogin(data);
      else history.push("/");
    } catch (err) {
      setError("Error logging in. Please try again.");
    }

    setIsLoading(false);
  };

  return (
    <Amplitude
      eventProperties={{
        scope: ["login"],
      }}
    >
      <LogOnMount eventType="login" />
      <main className="relative flex flex-col gap-[1.9rem] bg-[#fafafa]">
        <img
          className="absolute left-0 top-[2rem] z-0 w-[22rem]"
          src="/img/login/vector1.png"
        />
        <img
          className="absolute right-0 top-[2rem] z-0 w-[32rem]"
          src="/img/login/vector2.png"
        />
        <section className="z-10 flex flex-col items-center gap-[1.3rem] pt-[10rem]">
          <h2 className="m-0 text-center text-[2.25rem] font-semibold text-neutral-800 transition duration-1000 lg:whitespace-nowrap">
            Welcome back!
          </h2>
          <p className="m-0 text-center text-[1.25rem] font-normal text-neutral-800">
            Please log in to continue
          </p>
        </section>

        <section className="z-10 flex flex-col items-center">
          <form
            onSubmit={handleSubmit}
            className="flex w-full max-w-[41rem] flex-col gap-[1.9rem]"
          >
            <label className="flex flex-col gap-[0.2rem]">
              <p className="text-xl font-semibold text-neutral-800">Email:</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email"
                className="text-md w-full rounded-md border border-[#D9D9D9] bg-white p-[0.6rem] text-neutral-800"
              />
            </label>
            <label className="flex flex-col gap-[0.2rem]">
              <p className="text-xl font-semibold text-neutral-800">
                Password:
              </p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
                className="text-md w-full rounded-md border border-[#D9D9D9] bg-white p-[0.6rem] text-neutral-800"
              />
            </label>
            {error && (
              <div className="Create-error">
                <p className="Create-errorLabel" style={{ display: "" }}>
                  {error}
                </p>
              </div>
            )}
            {!isLoading && (
              <button
                className="w-full rounded-md bg-[#F65077] px-3 py-2 text-base font-semibold uppercase text-white hover:opacity-80"
                type="submit"
                disabled={isLoading}
              >
                Log in
              </button>
            )}
            {isLoading && (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Loader size="md" />
              </div>
            )}
          </form>
        </section>
      </main>
    </Amplitude>
  );
};

export default Login;
