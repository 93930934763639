import React from "react";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";

const PrivacyPolicy = () => {
  return (
    <div className="Header-Margin--offset">
      {/* <div className="Shadow-gradient o-4"></div> */}
      <Amplitude
        eventProperties={{
          scope: ["privacy-policy"],
        }}
      >
        <LogOnMount eventType="privacy-policy" />
        <div className="g g-gap-6 w--medbold maxW--sm p--ver-med p--hor Policy-Container ">
          <div className="g g-gap-1">
            <h4 className="text-neutral-900">Privacy Policy</h4>
            <p className="text-lg text-neutral-600">
              Last Updated: July 18, 2023
            </p>
          </div>
          <div className="g g-gap-2">
            <p>
              Toby allows you to organize your browser tabs and access your key
              resources more efficiently. Toby is developed by Launch Labs, Inc.
              dba Axiom Zen (“Axiom Zen”, “we” or “us”).
            </p>
            <p>
              Axiom Zen is committed to protecting your privacy and personal
              information. This Privacy Policy (this “Policy”) sets out how we
              collect and process personal information about you when you visit
              our website at{" "}
              <a
                className="c--brand"
                href="https://www.gettoby.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gettoby.com
              </a>
              , when you use our Chrome extension, when you use any of our
              products and services (collectively, our “Services”). This privacy
              policy relates to all of our activities, unless we have provided
              you with a separate privacy policy for a particular product,
              service or activity.
            </p>
            <p>
              Please read this policy carefully to understand how we handle and
              treat your personal information.
            </p>
          </div>
          <div className="g g-gap-2">
            <h6 className="text-neutral-800">
              What is covered in this Policy?
            </h6>
            <ul>
              <li>
                <a href="#meaning-of-personal-information">
                  Meaning of Personal Information
                </a>
              </li>
              <li>
                <a href="#your-consent-to-collection-use-and-disclosure">
                  Your Consent to Collection, Use and Disclosure
                </a>
              </li>
              <li>
                <a href="#our-collection-of-information">
                  Our Collection of Information
                </a>
              </li>
              <li>
                <a href="#our-use-of-cookies">Our Use of Cookies</a>
              </li>
              <li>
                <a href="#how-we-use-your-information">
                  How We Use Your Information
                </a>
              </li>
              <li>
                <a href="#how-we-share-your-information">
                  How We Share Your Information
                </a>
              </li>
              <li>
                <a href="#how-we-protect-your-information">
                  How We Protect Your Information
                </a>
              </li>
              <li>
                <a href="#your-rights-regarding-your-personal-information">
                  Your Rights Regarding Your Personal Information
                </a>
              </li>
              <li>
                <a href="#where-we-store-and-process-personal-information-international-transfers">
                  Where we Store and Process Personal Information; International
                  Transfers
                </a>
              </li>
              <li>
                <a href="#data-retention">Data Retention</a>
              </li>
              <li>
                <a href="#childrens-information">Children’s Information</a>
              </li>
              <li>
                <a href="#changes-to-our-policy">Changes to our Policy</a>
              </li>
              <li>
                <a href="#how-to-contact-us">How to Contact Us</a>
              </li>
            </ul>
          </div>
          <div id="meaning-of-personal-information" className="g g-gap-2">
            <h6 className="text-neutral-800">
              Meaning of Personal Information
            </h6>
            <p>
              “Personal information” means information about an identifiable
              individual. This information may include, but is not limited to,
              your name, mailing address, e-mail address, telephone number,
              country of residence and state/province of residence.
            </p>
            <p>
              Depending on applicable privacy law, personal information may not
              include any business contact information that is solely used to
              communicate with you in relation to your employment, business or
              profession, such as your name, position name or title, business
              telephone number, fax number or email.
            </p>
            <p>
              Personal information does not include information where there is
              no serious possibility that it can be used to identify an
              individual, whether on its own or in combination with other
              information, or personal information that has been anonymized or
              aggregated such as to be considered anonymized information or no
              longer personal information under applicable laws.
            </p>
          </div>
          <div
            id="your-consent-to-collection-use-and-disclosure"
            className="g g-gap-2"
          >
            <h6 className="text-neutral-800">
              Your Consent to Collection, Use and Disclosure
            </h6>
            <p>
              In Canada, we collect, use and disclose your personal information
              with your consent or as permitted or required by law. How we
              obtain your consent (i.e. the form we use) will depend on the
              circumstances, as well as the sensitivity of the information
              collected. Subject to applicable laws, your consent may be express
              or implied, depending on the circumstances and the sensitivity of
              the personal information in question. If you choose to provide
              personal information to us, we assume that you consent to the
              collection, use and disclosure of your personal information as
              outlined in this privacy policy.
            </p>
            <p>
              Typically, we will seek your consent at the time your personal
              information is collected. Where we want to use your personal
              information for a purpose not previously identified to you at the
              time of collection, we will seek your consent prior to our use of
              such information for this new purpose.
            </p>
            <p>
              You may withdraw your consent to our collection, use or disclosure
              of your personal information at any time by contacting us using
              the contact information in the “Contact Us” section below.
              However, before we implement the withdrawal of consent, we may
              require proof of your identity. In some cases, withdrawal of your
              consent may mean that we will no longer be able to provide certain
              products or services.
            </p>
            <p>
              If you provide personal information about another individual to
              us, it is your responsibility to obtain the consent of that
              individual to enable us to collect, such information from you, and
              to use and disclose his or her information as described in this
              privacy policy.
            </p>
          </div>
          <div id="our-collection-of-information" className="g g-gap-2">
            <h6 className="text-neutral-800">Our Collection of Information</h6>
            <p className="text-xl font-bold italic">
              How we collect Personal Information
            </p>
            <p>
              We collect Personal Information from you in the following ways:
            </p>
            <ul>
              <li>
                Through your use of the Services, whether directly created by
                you, or automatically collected by us as you use the Services.
                This includes individuals who use the Services in the course of
                their employment or at the direction of their employer.
              </li>
              <li>
                Through your engagement with our Services, including information
                submitted by you within a website form or obtained from us
                through cookies or similar technologies.
              </li>
              <li>
                From other users within the Services, with whom you may work
                with or otherwise have an ongoing professional relationship
                with.
              </li>
              <li>
                From other third party sources, such as our partners and service
                providers.
              </li>
            </ul>
            <p className="text-xl font-bold italic">
              What types of Personal Information we collect
            </p>
            <p>
              The data we collect depends on the context of your interactions
              with Toby, the choices you make (including your privacy settings),
              and the Services you use. The data we collect can include the
              following:
            </p>
            <ul>
              <li>
                <i>Account and Profile Information.</i> We collect the minimum
                amount of Personal Information necessary for you to use the
                Services. If you use a guest account to access the Services, we
                collect your Internet Protocol (IP) address, location and device
                information. If you use any other type of account to access the
                Services, we collect your name, email address, IP address,
                location and device information. Your tabs are kept in your
                local Chrome storage, and we do not keep track of any sites or
                tabs that you add to Toby. If you sign up for a Toby account, we
                store the tabs and lists you add to Toby as part of our service,
                but not the sites you visit.
              </li>
              <li>
                <i>Customer information</i>: When you use our Services, you will
                control what data is sent to and stored on our systems. For
                example, you might upload browser tabs or other browsing data
                into Toby in order to use the Services.
              </li>
              <li>
                <i>Device and Usage information.</i> We may collect data about
                your device and how you and your device interact with Toby and
                our Services. For example, we may collect data about the
                features you use, the Services you purchase, the web pages you
                visit, your device and the network you use to connect to our
                Services. This may include data about the operating system and
                other software installed on your device, including product keys.
                It may also include IP address, browser type, operating system,
                and referring URLs.
              </li>
              <li>
                <i>Payment Information:</i> We collect certain payment and
                billing information when you register for the Services. For
                example, we may ask that you designate a billing representative,
                including name and contact information, upon registration.
              </li>
            </ul>
          </div>
          <div id="our-use-of-cookies" className="g g-gap-2">
            <h6 className="text-neutral-800">Our use of cookies</h6>
            <p>
              We use cookies and other similar identifiers to understand and
              save your preferences for future visits, to advertise to you on
              other sites, and to compile aggregate data about site traffic and
              site interaction so that we can offer better site experiences and
              tools in the future. Cookies are small files that a site or its
              service provider transfers to your computer's hard drive through
              your web browser (if you allow) that enables the sites or service
              providers systems to recognize your browser and capture and
              remember certain information. You can choose to disable cookies,
              but if you do, your ability to use or access certain parts of our
              website may be affected.
            </p>
            <p>
              You may refuse to accept cookies by activating the setting on your
              browser that allows you to refuse the setting of cookies. You can
              find information on popular browsers and how to adjust your cookie
              preferences at the following websites:
            </p>
            <ul>
              <li>Microsoft Internet Explorer</li>
              <li>Mozilla Firefox</li>
              <li>Google Chrome</li>
              <li>Apple Safari</li>
            </ul>
            <p>
              A banner asking you to accept our cookies policy will be displayed
              upon the first visit to our website (or the first visit after you
              delete your cookies). Unless you have adjusted your browser
              setting so that it will refuse cookies and/or you have not
              accepted our cookies policy, our system will issue cookies when
              you log on to our site.
            </p>
            <p>
              Our web pages may contain electronic images known as web beacons
              (also called single-pixel gifs) that we use to help deliver
              cookies on our websites, and to count users who have visited those
              websites. We may also include web beacons in our promotional email
              messages or newsletters, to determine whether and when you open
              and act on them.
            </p>
            <p>
              In addition to placing web beacons on our own websites, we
              sometimes work with other companies to place our web beacons on
              their websites or in their advertisements. This helps us to
              develop statistics on how often clicking on an advertisement on a
              Toby website results in a purchase or other action on the
              advertiser’s website.
            </p>
            <p>
              Finally, our Services may contain web beacons or similar
              technologies from third-party analytics providers that help us
              compile aggregated statistics about the effectiveness of our
              promotional campaigns or other operations. These technologies
              enable the analytics providers to set or read their own cookies or
              other identifiers on your device, through which they can collect
              information about your online activities across applications,
              websites or other products. However, we prohibit these analytics
              providers from using web beacons on our sites to collect or access
              information that directly identifies you (such as your name or
              email address).
            </p>
          </div>
          <div id="how-we-use-your-information" className="g g-gap-2">
            <h6 className="text-neutral-800">How We Use Your Information</h6>
            <p>
              We use the data we collect to operate our business, and to provide
              the Services to you. This includes using the data to improve our
              Services, and to personalize your experiences. We may also use the
              data to communicate with you to, among other things, inform you
              about your account, provide security updates, and give you
              information about the Services. Specifically, we use the data in
              the following ways:
            </p>
            <ul>
              <li>
                <i>Providing the Services.</i> We use data to carry out your
                transactions with us and to provide the Services to you, to
                maintain the Services and diagnose product problems, and to
                provide customer support. In certain cases, the Services include
                personalized features and recommendations that enhance your
                productivity and enjoyment, and automatically tailor your
                experience based on the data we have about you.
              </li>
              <li>
                <i>Improving our Services.</i> We use data to improve the
                Services we offer, and to perform essential business operations.
                This includes improving the performance of the Services,
                developing new features, and conducting research to better
                understand our users.
              </li>
              <li>
                <i>Business Operations.</i> We use data to develop aggregate
                analyses and business intelligence that enable us to operate,
                protect, make informed decisions, and report on the performance
                of our business. We also use data to protect against wrongdoing,
                to enforce our Terms of Service, and to generally manage our
                business
              </li>
              <li>
                <i>Promotions.</i> We may use your data to administer contests,
                promotions, surveys, or other site features.
              </li>
              <li>
                <i>Communicating with you.</i> We may use your data to respond
                to your enquiries and requests relating to our Services, to
                create and administer your accounts and to send you periodic
                emails For example, we may contact you to discuss your account,
                to let you know when updates are available, to remind you about
                features of the Services that are available for your use, to
                update you about a support request, or to invite you to
                participate in a survey. Additionally, you can sign up for email
                subscriptions, and choose whether you want to receive marketing
                communications from us. Depending on the marketing preferences
                you select on your privacy dashboard, we may send you occasional
                marketing emails about our products and services, which you can
                unsubscribe from at any time using the link provided in the
                message.
              </li>
            </ul>
          </div>
          <div id="how-we-share-your-information" className="g g-gap-2">
            <h6 className="text-neutral-800">How We Share Your Information</h6>
            <p>
              We share your Personal Information with your consent, or as
              necessary to provide the Services to you. We also share Personal
              Information with vendors or agents working on our behalf for the
              purposes described in this Policy. For example, companies we have
              hired to provide cloud hosting services, off-site backups, and
              customer support may need access to Personal Information to
              provide those functions. In such cases, these companies are
              required to abide by our data privacy and security requirements
              and are not allowed to use Personal Information they receive from
              us for any other purpose. If you have questions or concerns about
              any of our vendors, feel free to contact us at{" "}
              <a
                className="c--brand"
                href="mailto:hello@gettoby.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@gettoby.com
              </a>
              .
            </p>
            <p>
              We also share your Personal Information in the following ways:
            </p>
            <ul>
              <li>
                as part of a corporate transaction such as a corporate sale,
                merger, reorganization, dissolution, or similar event;to comply
                with applicable law or respond to valid legal process, judicial
                orders, or subpoenas;
              </li>
              <li>
                to respond to requests from public or governmental authorities,
                including for national security or law enforcement purposes;
              </li>
              <li>
                to protect the vital interests of our users, customers, or other
                third parties (including, for example, to prevent spam or
                attempts to defraud users of our products, or to help prevent
                the loss of life or serious injury of anyone);
              </li>
              <li>
                to operate and maintain the security of our Services, including
                to prevent or stop an attack on our computer systems or
                networks;
              </li>
              <li>
                to protect the rights, interests or property of Axiom Zen or
                third parties;
              </li>
              <li>
                to prevent or investigate possible wrongdoing in connection with
                the Services; or
              </li>
              <li>to enforce our Terms of Service.</li>
            </ul>
            <p>
              We may use and share aggregated non-personal information with
              third parties for marketing, advertising, and analytics purposes.
            </p>
            <p>
              We do not sell or trade your personal information to third
              parties.
            </p>
          </div>
          <div id="how-we-protect-your-information" className="g g-gap-2">
            <h6 className="text-neutral-800">
              How We Protect Your Information
            </h6>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information when you enter, submit, or access
              your personal information. We offer the use of a secure server.
              All supplied sensitive information is transmitted via Secure
              Socket Layer (SSL) technology, and then encrypted into our
              database only to be accessible by those authorized with special
              access rights to such systems, and are required to keep the
              information confidential.
            </p>
            <p>
              We implement a variety of measures with an aim to ensure that our
              processing systems remain confidential, resilient, and available,
              including the following:.
            </p>
            <ul>
              <li>
                Availability. Every part of the Services utilize
                properly-provisioned, redundant servers (e.g., multiple load
                balancers, web servers, replica databases) in case of failure.
                We take servers out of operation as part of regular maintenance,
                to reduce impact on availability.
              </li>
              <li>
                Business Continuity. We keep encrypted backups of data every
                hour in multiple regions. While never expected, in the case of
                production data loss (i.e., primary data stores loss), we will
                aim to restore organizational data from these backups.
              </li>
              <li>
                Disaster Recovery. In the event of a region-wide outage, we will
                aim to bring up a duplicate environment in a different region.
                Our operations team has extensive experience performing full
                region migrations.
              </li>
              <li>
                Physical Access Controls. Toby is hosted on Heroku. Heroku data
                centers feature a layered security model, including extensive
                safeguards such as custom-designed electronic access cards,
                alarms, vehicle access barriers, perimeter fencing, metal
                detectors, and biometrics. According to the Heroku Security
                Whitepaper: “Heroku utilizes ISO 27001 and FISMA certified data
                centers managed by Amazon. Amazon has many years of experience
                in designing, constructing, and operating large-scale data
                centers. This experience has been applied to the AWS platform
                and infrastructure. AWS data centers are housed in nondescript
                facilities, and critical facilities have extensive setback and
                military grade perimeter control berms as well as other natural
                boundary protection. Physical access is strictly controlled both
                at the perimeter and at building ingress points by professional
                security staff utilizing video surveillance, state-of-the-art
                intrusion detection systems, and other electronic means.
                Authorized staff must pass two-factor authentication no fewer
                than three times to access data center floors. All visitors and
                contractors are required to present identification and are
                signed in and continually escorted by authorized staff. Amazon
                only provides data center access and information to employees
                who have a legitimate business need for such privileges. When an
                employee no longer has a business need for these privileges, his
                or her access is immediately revoked, even if they continue to
                be an employee of Amazon or Amazon Web Services. All physical
                and electronic access to data centers by Amazon employees is
                logged and audited routinely.” Axiom Zen employees do not have
                physical access to Heroku data centers, servers, network
                equipment, or storage.
              </li>
              <li>
                Logical Access Controls. Toby is the assigned administrator of
                its infrastructure, and only designated authorized Toby
                operations team members have access to configure the
                infrastructure on an as-needed basis behind a two-factor
                authenticated virtual private network. Specific private keys are
                required for individual servers, and keys are stored in a secure
                and encrypted location.
              </li>
              <li>
                Intrusion Detection and Prevention. Unusual network patterns or
                suspicious behavior are among Toby’s biggest concerns for
                infrastructure hosting and management. Heroku’s intrusion
                detection and prevention systems (IDS/ IPS) rely on both
                signature-based and algorithm-based security to help identify
                traffic patterns that are similar to known attack methods.
                IDS/IPS involves tightly controlling the size and make-up of the
                attack surface, employing intelligent detection controls at data
                entry points, and developing and deploying technologies that
                automatically remedy dangerous situations, as well as preventing
                known threats from accessing the system in the first place. We
                do not provide direct access to security event forensics, but we
                do provide access to our engineering and customer support teams
                during and after any unscheduled downtime.
              </li>
            </ul>
          </div>
          <div
            id="your-rights-regarding-your-personal-information"
            className="g g-gap-2"
          >
            <h6 className="text-neutral-800">
              Your Rights Regarding Your Personal Information
            </h6>
            <p>
              We will take steps to ensure that your personal information is
              kept as accurate, complete and up-to-date as reasonably necessary.
              We will not routinely update your personal information, unless
              such a process is necessary. We expect you, from time to time, to
              supply us with updates to your personal information, when
              required.
            </p>
            <p>
              You can view, access, edit, delete, or request a copy of your
              Personal Information for many aspects of the Services via your
              user dashboard. You can also make choices about Toby’s collection
              and use of your data and you can opt out from receiving marketing
              communications from us by using the opt-out link on the
              communication, or by visiting your user dashboard.
            </p>
            <p>
              <b>Data Access.</b> You can access your Personal Information on
              your account’s user dashboard.
            </p>
            <p>
              <b>Data Portability.</b> You can request a copy of your Personal
              Information by submitting an email to us at{" "}
              <a
                className="c--brand"
                href="mailto:hello@gettoby.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@gettoby.com
              </a>{" "}
              and including “Please send me a copy of my Personal Information”
              in the “Subject” line. Axiom Zen will verify your ability to
              access that email, then send you a digital export of the data we
              hold that is associated with your email address. We will use
              reasonable efforts to respond to your request within 14 days, but
              in all events within 30 days of our receipt of the request.
            </p>
            <p>
              <b>Data Erasure.</b> You can delete your Personal Information on
              your account’s user dashboard. Please be aware that we require
              certain information about you in order to provide the Services;
              this means that if you want to delete those critical pieces of
              Personal Information, you may be required to delete your entire
              profile and no longer be able to access or use the Services.
              Alternatively, you may request that Axiom Zen delete your Personal
              Information by submitting an email to us at{" "}
              <a
                className="c--brand"
                href="mailto:hello@gettoby.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@gettoby.com
              </a>{" "}
              and including “Please delete my Personal Information” in the
              “Subject” line. Axiom Zen will verify your ability to access that
              email, then delete the Personal Information associated with your
              email address. All customer data stored on our servers is
              eradicated upon a customer’s termination of service and deletion
              of account after a 24-hour waiting period to prevent accidental
              cancellation. We will use reasonable efforts to respond to your
              request within 14 days, but in all events within 30 days of our
              receipt of the request. Please note that we retain usage metadata
              about a company or individual as required for compliance with law
              and regulation.
            </p>
            <p>
              <b>Data Correction.</b> You can modify your Personal Information
              on your account’s user dashboard.
            </p>
            <p>
              <b>Your Communications Preferences.</b> You can choose whether you
              wish to receive marketing communications from us. If you receive
              marketing communications from us and would like to opt out, you
              can do so by following the directions in that communication. You
              can also make choices about your receipt of marketing
              communications by signing into your account, and viewing and
              managing your communication permissions in your account’s user
              dashboard, where you can update contact information, manage your
              contact preferences, opt out of email subscriptions, and choose
              whether to share your contact information with Toby and our
              partners. Alternatively, you can request that we withdraw consent
              to use your Personal Information by submitting an email to us at{" "}
              <a
                className="c--brand"
                href="mailto:hello@gettoby.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@gettoby.com
              </a>
              , and including “Please withdraw my consent for marketing
              communications” in the “Subject” line. Axiom Zen will verify your
              ability to access that email, then update our systems to remove
              your email address from the system we use to send marketing
              communications. We will use reasonable efforts to respond to your
              request within 14 days, but in all events within 30 days of our
              receipt of the request. Please note that these choices do not
              apply to mandatory communications that are part of the Services,
              or to surveys or other informational communications that have
              their own unsubscribe method.
            </p>
            <p>
              Where applicable, under the GDPR, you may be entitled to
              additional rights, including: (i) the right to withdraw consent to
              processing where consent is the basis of processing; (ii) the
              right to object to unlawful data processing, under certain
              conditions; (iii) the right to erasure of personal information
              about you, under certain conditions; (iv) the right to demand that
              we restrict processing of your personal information, under certain
              conditions, if you believe we have exceeded the legitimate basis
              for processing, processing is no longer necessary, are processing,
              or believe your personal information is inaccurate; (v) the right
              to object to decisions being taken by automated means which
              produce legal effects concerning you or similarly significantly
              affect you, under certain conditions. If you want to learn more
              about your rights under the GDPR, you can visit the European
              Commission’s page on Data Protection at:{" "}
              <a
                target="_blank"
                href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en"
                rel="noreferrer"
              >
                https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en
              </a>.
            </p>
            <p>
              You also have the right to lodge a complaint with the relevant
              data protection authority.
            </p>
            <p className="text-xl font-bold italic">Third Party Links</p>
            <p>
              Occasionally, at our discretion, we may include or offer third
              party products or services on our website or through our Services.
              If you access other websites using the links provided, the
              operators of these websites may collect information from you that
              will be used by them in accordance with their privacy policies.
              These third party sites have separate and independent privacy
              policies. We, therefore, have no responsibility or liability for
              the content and activities of these linked sites. Nonetheless, we
              seek to protect the integrity of our site and welcome any feedback
              about these sites.
            </p>
          </div>
          <div
            id="where-we-store-and-process-personal-information-international-transfers"
            className="g g-gap-2"
          >
            <h6 className="text-neutral-800">
              Where we Store and Process Personal Information; International
              Transfers
            </h6>
            <p>
              We primarily use and store your personal information in Canada and
              the United States. Your personal information may be stored and
              processed in any country where we have facilities or in which we
              engage third party service providers. to fulfil the purpose for
              which we collected the personal information. As a result, your
              personal information may be transferred to countries outside your
              country of residence, which may have different data protection
              rules than in your country. While such information is outside of
              your country, it is subject to the laws of the country in which it
              is located, and may be subject to disclosure to the governments,
              courts or law enforcement or regulatory agencies of such other
              country, pursuant to the laws of such country.
            </p>
            <p>
              However, our practices regarding your personal information will at
              all times continue to be governed by this privacy statement and,
              if applicable, we will comply with the GDPR requirements providing
              adequate protection for the transfer of personal information from
              the EU/EEA to third country as well as other legal requirements
              that may apply to such transfer. To learn more about the European
              Commission’s decisions on the adequacy of the protection of
              Personal Information in the countries where Toby processes
              Personal Information, please visit:{" "}
              <a
                target="_blank"
                href="https://commission.europa.eu/law/law-topic/data-protection_en"
                rel="noreferrer"
              >
                https://commission.europa.eu/law/law-topic/data-protection_en
              </a>
            </p>
          </div>
          <div id="data-retention" className="g g-gap-2">
            <h6 className="text-neutral-800">Data Retention</h6>
            <p>
              We may retain your personal information as long as you continue to
              use the Services, have an account with us, or for as long as is
              necessary to fulfill the purposes outlined in this Policy. You can
              ask to close your account by contacting us as described above, and
              we will delete your personal information on request.
            </p>
            <p>
              We may, however, retain personal information for an additional
              period as is permitted or required under applicable laws, for
              legal, tax, or regulatory reasons, or for legitimate and lawful
              business purposes.
            </p>
            <p>
              We will retain your Personal Information for as long as necessary
              to provide the Services to you, or for other essential purposes
              such as complying with our legal obligations, resolving disputes,
              and enforcing our agreements. Because these needs can vary for
              different types of data, actual retention periods can vary
              significantly. The criteria we use to determine the retention
              periods include:
            </p>
            <ul>
              <li>
                How long is the Personal Information needed to provide the
                Services and/or operate our business? This includes such things
                such as maintaining and improving the performance of the
                Services, keeping our systems secure, and maintaining
                appropriate business and financial records. This is the general
                rule that establishes the baseline for most data retention
                periods.
              </li>
              <li>
                Is there an automated control, such as in your user dashboard,
                that enables you to access and delete the Personal Information
                at any time? If there is not, a shortened data retention time
                will generally be adopted.
              </li>
              <li>
                Is the Personal Information of a sensitive type? If so, a
                shortened retention time would generally be appropriate.
              </li>
              <li>
                Has the user provided consent for a longer retention period? If
                so, we will retain the data in accordance with your consent.
              </li>
              <li>
                Is Axiom Zen subject to a legal, contractual, or similar
                obligation to retain the data? Examples can include mandatory
                data retention laws in the applicable jurisdiction, government
                orders to preserve data relevant to an investigation, or data
                that must be retained for the purposes of litigation.
              </li>
            </ul>
          </div>
          <div id="childrens-information" className="g g-gap-2">
            <h6 className="text-neutral-800">Children’s Information</h6>
            <p>
              Our products and services are not intended for children under the
              age of 16 and other minors, and we do not knowingly collect
              personal information from children under the age of 16 and other
              minors. Children under the age of 16 or other minors should not
              use our products and services and should not provide us with their
              personal information.
            </p>
          </div>
          <div id="changes-to-our-policy" className="g g-gap-2">
            <h6 className="text-neutral-800">Changes to our Privacy Policy</h6>
            <p>
              We will update this privacy statement when necessary to reflect
              customer feedback and changes in our Services. When we post
              changes to this statement, we will revise the “last updated” date
              at the top of the statement. If there are material changes to the
              statement or in how Toby will use your Personal Information, we
              will notify you either by prominently posting a notice of such
              changes before they take effect or by sending you a notification
              directly. We encourage you to periodically review this privacy
              statement to learn how Toby is protecting your information.
            </p>
          </div>
          <div id="how-to-contact-us" className="g g-gap-2">
            <h6 className="text-neutral-800">How to Contact Us</h6>
            <p>
              If you have a technical or support question, please send us an
              email at{" "}
              <a className="c--brand" href="mailto:hello@gettoby.com">
                hello@gettoby.com.
              </a>
            </p>
            <p>
              If you have a privacy concern, complaint, or a question for the
              Data Protection Officer of Toby, please contact us by sending us
              an email at{" "}
              <a className="c--brand" href="mailto:hello@gettoby.com">
                hello@gettoby.com.
              </a>
              We will respond to questions or concerns within 30 days.
            </p>
            <p>
              Unless otherwise stated, Toby is a data controller for personal
              data we collect through the Services subject to this statement.
              Our address is Launch Labs, Inc. dba Toby, 980-350 Howe Street,
              Vancouver, BC, Canada V6Z 1N9.
            </p>
          </div>
        </div>
      </Amplitude>
    </div>
  );
};

export default PrivacyPolicy;
