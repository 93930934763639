import React from "react";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import { InView } from "react-intersection-observer";
// import { Button } from "./core/Button";

import "../styles/main.css";
import Marquee from "./Marquee.jsx";
import clsx from "clsx";
import { getBrowserCTA } from "../helpers";

const { href } = getBrowserCTA();

export const GetStarted = () => {
  return (
    <section className="relative flex overflow-hidden border border-rose-200 bg-rose-50 px-4 pt-[120px] text-center text-neutral-800">
      <img
        className="absolute left-0 top-20 z-0"
        src="../img/product/blob-shape-3.svg"
        alt="Shape"
      />
      <img
        className="absolute left-[15%] top-20 z-0"
        src="../img/product/blob-shape-2.svg"
        alt="Shape"
      />
      <div className="z-[1] mx-auto flex max-w-fit flex-col">
        <h1 className="mb-2 text-[40px] font-light">Let’s get started!</h1>
        <p className="mb-9 text-xl font-light leading-none">
          See why we have over 500,000+ users!
        </p>
        <a
          role="button"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="js-Button--install mx-auto mb-9 mt-3.5 inline-block max-w-min whitespace-nowrap rounded bg-brand-300 px-20 py-3 font-semibold uppercase text-white transition-colors hover:bg-brand-400"
        >
          Install toby
        </a>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <img
              ref={ref}
              className={clsx(
                "rounded-t-[5px] shadow-high-hover duration-700 lg:mx-10 lg:max-w-5xl",
                inView ? "opacity-1 translate-y-0" : "translate-y-10 opacity-0"
              )}
              src="../img/product/toby-preview-2.jpg"
              alt="Preview of Toby"
            />
          )}
        </InView>
      </div>
    </section>
  );
};

const Main = () => (
  <Amplitude
    eventProperties={{
      scope: ["main"],
    }}
  >
    <LogOnMount eventType="main" />
    <main className="flex flex-col bg-[#fafafa]">
      <section className="mt-[134px] flex min-h-[calc(100vh-134px)] flex-col sm:mt-[104px] sm:min-h-[calc(100vh-104px)]">
        <div className="flex flex-1 flex-col-reverse items-center gap-4 xl:container lg:flex-row lg:gap-32 xl:mx-auto">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className={clsx(
                  "z-[1] flex flex-1 flex-col items-center gap-5 px-10 text-center lg:items-start lg:text-start"
                )}
              >
                <h1
                  className={clsx(
                    "text-4xl font-semibold text-neutral-800 transition duration-1000 lg:whitespace-nowrap",
                    inView
                      ? "opacity-1 translate-y-0"
                      : "translate-y-10 opacity-0"
                  )}
                >
                  All your documents,
                  <br />
                  one-click away
                </h1>
                <p
                  className={clsx(
                    "max-w-xl text-xl text-neutral-800 transition delay-200 duration-[800ms]",
                    inView
                      ? "opacity-1 translate-y-0"
                      : "translate-y-8 opacity-0"
                  )}
                >
                  Boost your team collaboration and productivity with a central
                  hub for all your teams links and documents.
                </p>
                <div
                  className={clsx(
                    "transition delay-[400ms] duration-[600ms]",
                    inView
                      ? "opacity-1 translate-y-0"
                      : "translate-y-10 opacity-0"
                  )}
                >
                  <a
                    role="button"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(
                      "js-Button--install mt-3.5 inline-block rounded bg-brand-300 px-20 py-3 font-semibold uppercase text-white transition-colors hover:bg-brand-400"
                    )}
                  >
                    Install toby
                  </a>
                </div>
              </div>
            )}
          </InView>
          <div className="flex-1">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div
                  ref={ref}
                  className={clsx(
                    "relative mt-4 flex flex-col px-4 transition duration-[1200ms] lg:px-0",
                    inView
                      ? "opacity-1 translate-y-0 lg:translate-x-0"
                      : "translate-y-20 opacity-0 lg:translate-x-20"
                  )}
                >
                  <div className="flex items-center justify-center">
                    <img
                      src="../img/product/hero-blob-shape.svg"
                      alt="Shape"
                      className="absolute inset-0 z-[1] hidden -translate-y-10 lg:block"
                    />
                    <img
                      src="../img/product/email-preview.svg"
                      alt="Shape"
                      className="absolute z-[1] hidden -translate-x-full lg:block"
                    />
                    <img
                      src="../img/product/contract-preview.svg"
                      alt="Shape"
                      className="absolute z-[1] hidden -translate-x-[90%] translate-y-[170%] lg:block"
                    />
                    <img
                      src="../img/product/meeting-preview.svg"
                      alt="Shape"
                      className="absolute z-[1] hidden -translate-y-[205%] translate-x-full lg:block"
                    />
                    <img
                      className="z-[2] rounded-[5px] shadow-high-hover lg:max-w-[600px] xl:ml-20"
                      src="../img/product/toby-preview.jpg"
                      alt="Preview of Toby"
                    />
                  </div>
                  <div className="z-[1] flex flex-row items-center px-2 py-3.5 font-normal text-black">
                    <p className="ml-auto mr-5 text-xs opacity-40">
                      AVAILABLE ON
                    </p>
                    <a
                      className="mr-2"
                      href="https://chrome.google.com/webstore/detail/toby-for-chrome/hddnkoipeenegfoeaoibdmnaalmgkpip"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="max-w-[22px]"
                        src="img/browsers/chrome.svg"
                        alt="Google Chrome"
                      />
                    </a>
                    <a
                      className="mr-2"
                      href="https://chrome.google.com/webstore/detail/toby-for-chrome/hddnkoipeenegfoeaoibdmnaalmgkpip"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="max-w-[22px]"
                        src="img/browsers/edge.svg"
                        alt="Microsoft Edge"
                      />
                    </a>
                    <a
                      href="https://addons.mozilla.org/en-CA/firefox/addon/toby-for-tabs/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="max-w-[24px]"
                        src="img/browsers/firefox.svg"
                        alt="Firefox"
                      />
                    </a>
                  </div>
                </div>
              )}
            </InView>
          </div>
        </div>
        <Marquee className="py-12" />
      </section>
      <section className="relative flex min-h-[50vh] flex-col items-center justify-center gap-4 py-20 lg:flex-row">
        <img
          src="../img/product/dots.svg"
          alt="Dots"
          className="absolute left-0 top-20 z-[0] hidden lg:block"
        />
        <img
          src="../img/product/blob-shape-1.svg"
          alt="Blob"
          className="absolute left-0 top-20 z-[0]"
        />
        <div className="container z-[1] mx-auto flex items-center px-10">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <h1
                ref={ref}
                className={clsx(
                  "z-0 max-w-[750px] text-center text-4xl font-normal text-neutral-800 transition duration-1000 lg:text-left",
                  inView
                    ? "opacity-1 translate-y-0"
                    : "translate-y-20 opacity-0"
                )}
              >
                Toby is trusted by over 500,000 users - from startups to Fortune
                500 companies
              </h1>
            )}
          </InView>
        </div>
        <img
          src="../img/product/dots.svg"
          alt="Dots"
          className="absolute right-0 top-full z-[0] hidden lg:block"
        />
      </section>
      <section className="z-[1] flex min-h-[50vh] flex-col-reverse items-center gap-8 px-4 py-20 text-center lg:container lg:mx-auto lg:flex-row lg:px-0 lg:text-left">
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="flex flex-1 flex-col justify-center gap-2"
            >
              <h2
                className={clsx(
                  "text-3xl font-semibold text-neutral-800 transition duration-1000",
                  inView
                    ? "opacity-1 translate-y-0"
                    : "translate-y-20 opacity-0"
                )}
              >
                Collect all your resources
              </h2>
              <p
                className={clsx(
                  "max-w-[580px] text-neutral-800 transition delay-[400ms] duration-[600ms]",
                  inView
                    ? "opacity-1 translate-y-0"
                    : "translate-y-10 opacity-0"
                )}
              >
                Add all your documents, designs, project boards and other
                important resources to Toby in one click.
              </p>
            </div>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="relative flex w-full flex-1 flex-col items-center justify-center gap-8"
            >
              <img
                className={clsx(
                  "transition duration-[1200ms]",
                  inView
                    ? "opacity-1 translate-x-0"
                    : "translate-x-20 opacity-0"
                )}
                src="../img/product/tabs-preview.png"
                alt="Tabs"
              />
              {/* <span
                className={clsx(
                  "flex w-[230px] select-none items-center justify-center gap-[5px] rounded-[5px] border-[1.5px] border-[#F65077] px-[5px] py-[10px] font-semibold text-[#F65077] transition duration-[1200ms]",
                  inView
                    ? "opacity-1 translate-x-0"
                    : "translate-x-20 opacity-0"
                )}
              >
                <img
                  className="z-[2]"
                  src="../img/icons/download.svg"
                  alt="Download"
                />
                SAVE SESSION
              </span> */}
            </div>
          )}
        </InView>
      </section>
      <section className="relative flex min-h-[50vh] items-center justify-center py-20">
        <img
          src="../img/product/blob-shape-1.svg"
          alt="Blob"
          className="absolute left-0 top-0 z-[0] scale-110"
        />
        <div className="flex flex-col items-center gap-8 px-4 text-center lg:container lg:mx-auto lg:flex-row lg:px-0 lg:text-left">
          <div className="flex flex-1">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <img
                  ref={ref}
                  className={clsx(
                    "z-[2] mx-auto max-w-[62%] rounded-[5px] shadow-high-hover transition duration-[1200ms] xs:mx-0 xs:max-w-[230px] sm:max-w-[300px] md:max-w-[380px] lg:max-w-[260px]",
                    inView
                      ? "opacity-1 translate-x-0"
                      : "-translate-x-20 opacity-0"
                  )}
                  src="../img/product/sidebar-preview.jpg"
                  alt="Toby Sidebar"
                />
              )}
            </InView>
          </div>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className="z-[1] flex flex-1 flex-col justify-center gap-2"
              >
                <h2
                  className={clsx(
                    "text-3xl font-semibold text-neutral-800 transition duration-1000",
                    inView
                      ? "opacity-1 translate-y-0"
                      : "translate-y-20 opacity-0"
                  )}
                >
                  Organize your Spaces
                </h2>
                <p
                  className={clsx(
                    "max-w-[580px] text-neutral-800 transition delay-[400ms] duration-[600ms]",
                    inView
                      ? "opacity-1 translate-y-0"
                      : "translate-y-10 opacity-0"
                  )}
                >
                  Create workspaces for your projects and keep your documents
                  neat and organized.
                </p>
              </div>
            )}
          </InView>
        </div>
      </section>
      <section className="relative mb-64 flex min-h-[50vh] items-center justify-center py-20">
        <img
          src="../img/product/dots.svg"
          alt="Dots"
          className="absolute right-0 z-[0] hidden lg:block"
        />
        <div className="flex flex-col-reverse items-center gap-8 px-4 text-center lg:container lg:mx-auto lg:flex-row lg:px-0 lg:text-left">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className="z-[1] flex flex-1 flex-col justify-center gap-2"
              >
                <h2
                  className={clsx(
                    "text-3xl font-semibold text-neutral-800 transition duration-1000",
                    inView
                      ? "opacity-1 translate-y-0"
                      : "translate-y-20 opacity-0"
                  )}
                >
                  Collaborate efficiently
                </h2>
                <p
                  className={clsx(
                    "max-w-[580px] text-neutral-800 transition delay-[400ms] duration-[600ms]",
                    inView
                      ? "opacity-1 translate-y-0"
                      : "translate-y-10 opacity-0"
                  )}
                >
                  Make it easier for everyone to find the right documents at the
                  right time. No more chasing documents buried in your inbox or
                  team chat.
                </p>
              </div>
            )}
          </InView>
          <div className="z-[1] flex flex-1 items-center justify-center">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <img
                  ref={ref}
                  src="../img/product/add-member-preview.jpg"
                  alt="Add members"
                  className={clsx(
                    "max-w-[300px] rounded-[5px] shadow-high-hover transition duration-[1200ms] xs:max-w-[340px] sm:max-w-[500px] md:max-w-[600px] lg:max-w-[380px]",
                    inView
                      ? "opacity-1 translate-x-0"
                      : "translate-x-20 opacity-0"
                  )}
                />
              )}
            </InView>
          </div>
        </div>
      </section>
      <GetStarted />
    </main>
  </Amplitude>
);

export default Main;
