import React from "react";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";

const TobyLinksHowToPage = () => {
  return (
    <Amplitude
      eventProperties={{
        scope: ["tobyLinksHowToPage"],
      }}
    >
      <LogOnMount eventType="tobyLinksHowToPage" />
      <main className="relative flex flex-col bg-[#fafafa]">
        <img
          className="absolute right-0 z-0 w-[38rem]"
          src="/img/tobylinks/vector1.png"
        />
        <img
          className="absolute right-0 top-[4rem] z-0 w-[46rem]"
          src="/img/tobylinks/vector1_gray.png"
        />
        <section className="flex flex-col items-center px-6 pb-[16rem] pt-[10rem]">
          <div>
            <TobyLinkIcon />
          </div>
          <h1 className="text-center text-[2.5rem] font-semibold text-neutral-800 transition duration-1000 lg:whitespace-nowrap">
            Getting to know Toby Links
          </h1>
          <p className="mt-[0.5rem] text-center text-[1.25rem] font-normal text-neutral-800">
            Access your links faster by creating custom URLs.
          </p>
        </section>

        <section className="flex flex-col items-center gap-[16rem] px-6 pb-[13rem]">
          <TobyLinkStep>
            <div className="flex-1">
              <h2 className="text-[1.875rem] font-semibold text-neutral-800 transition duration-1000">
                Step 1. Click the Toby Link icon
              </h2>
              <p className="mt-[0.5rem] text-[1.25rem] font-normal text-neutral-800">
                You can create a Toby Link for a tab, collection, or a Space.
              </p>
            </div>
            <div className="flex flex-1 justify-center">
              <div className="flex flex-col items-center gap-[1.5rem]">
                <TobyLinkIcon />
                <button className="flex cursor-default flex-row gap-2 rounded-[0.4rem] bg-[#F65077] px-[0.8rem] py-[0.4rem] text-[0.875rem] font-semibold uppercase tracking-[0.0175rem] text-[#FFF] drop-shadow-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1243_2103)">
                      <path
                        d="M15.4538 11.9226H11.1829V16.1934C11.1829 16.6632 10.7986 17.0476 10.3288 17.0476C9.85898 17.0476 9.47461 16.6632 9.47461 16.1934V11.9226H5.20378C4.73398 11.9226 4.34961 11.5382 4.34961 11.0684C4.34961 10.5986 4.73398 10.2142 5.20378 10.2142H9.47461V5.9434C9.47461 5.47361 9.85898 5.08923 10.3288 5.08923C10.7986 5.08923 11.1829 5.47361 11.1829 5.9434V10.2142H15.4538C15.9236 10.2142 16.3079 10.5986 16.3079 11.0684C16.3079 11.5382 15.9236 11.9226 15.4538 11.9226Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1243_2103">
                        <rect
                          width="20.5"
                          height="20.5"
                          fill="white"
                          transform="translate(0.078125 0.818359)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Create Toby Link
                </button>
              </div>
            </div>
          </TobyLinkStep>

          <TobyLinkStep>
            <div className="flex w-[100%] flex-1 justify-center">
              <div className="relative h-[12rem] w-[100%] max-w-[24rem]">
                <img
                  className="absolute z-0 w-[22rem] translate-x-[5rem] translate-y-[-5rem]"
                  src="/img/tobylinks/vector2.png"
                />
                <img
                  className="absolute z-0 w-[22rem] translate-x-[-3.5rem] translate-y-[5.5rem]"
                  src="/img/tobylinks/mask_group2.png"
                />
                <img
                  className="absolute z-50 w-[22.3rem]"
                  src="/img/tobylinks/step2.png"
                />
              </div>
            </div>
            <div className="mt-6 flex-1 md:mt-0">
              <h2 className="text-[1.875rem] font-semibold text-neutral-800 transition duration-1000">
                Step 2. Create a custom name
              </h2>
              <p className="mt-[0.5rem] text-[1.25rem] font-normal text-neutral-800">
                Curate a new and easy name for the link. It’s best to keep it
                short and simple.
              </p>
            </div>
          </TobyLinkStep>

          <TobyLinkStep>
            <div className="flex-1">
              <h2 className="text-[1.875rem] font-semibold text-neutral-800 transition duration-1000">
                Step 3. Choose or paste the destination url
              </h2>
              <p className="mt-[0.5rem] text-[1.25rem] font-normal text-neutral-800">
                Search within your organization for a collection or a tab or
                paste the url in directly
              </p>
            </div>
            <div className="flex w-[100%] flex-1 justify-center">
              <div className="relative h-[12rem] w-[100%] max-w-[24rem]">
                <img
                  className="absolute z-0 w-[23.5rem] translate-x-[1rem] translate-y-[-3rem]"
                  src="/img/tobylinks/vector3.png"
                />
                <img
                  className="absolute z-0 w-[22rem] translate-x-[-3.5rem] translate-y-[-3.5rem]"
                  src="/img/tobylinks/mask_group2.png"
                />
                <img
                  className="absolute z-50 w-[22.3rem]"
                  src="/img/tobylinks/step3.png"
                />
              </div>
            </div>
          </TobyLinkStep>

          <TobyLinkStep>
            <div className="flex w-[100%] flex-1 justify-center">
              <div className="relative h-[4rem] w-[100%] max-w-[38rem]">
                <img
                  className="absolute z-0 w-[17rem] translate-x-[-3rem] translate-y-[-6rem]"
                  src="/img/tobylinks/vector3.png"
                />
                <img
                  className="absolute z-0 w-[22rem] translate-x-[-4.5rem] translate-y-[-4.5rem]"
                  src="/img/tobylinks/mask_group2.png"
                />
                <img
                  className="absolute z-50 w-[38rem]"
                  src="/img/tobylinks/step4.png"
                />
              </div>
            </div>
            <div className="flex-1">
              <h2 className="text-[1.875rem] font-semibold text-neutral-800 transition duration-1000">
                Step 4. Type “to/” then your custom name into the toolbar
              </h2>
              <p className="mt-[0.5rem] text-[1.25rem] font-normal text-neutral-800">
                When you need to access your resources, type in “to/” followed
                by the custom name you assigned to the tab or collection
              </p>
            </div>
          </TobyLinkStep>

          <TobyLinkStep>
            <div className="flex-1">
              <h2 className="text-[1.875rem] font-semibold text-neutral-800 transition duration-1000">
                Step 5. Your tab(s) open!
              </h2>
              <p className="mt-[0.5rem] text-[1.25rem] font-normal text-neutral-800">
                Voila! your tab will open or you will be directly connected to
                your collection!
              </p>
            </div>
            <div className="flex w-[100%] flex-1 justify-center">
              <div className="relative h-[12rem] w-[100%] max-w-[34rem]">
                <img
                  className="absolute z-0 w-[23.5rem] translate-x-[14rem] translate-y-[-6.5rem] md:translate-x-[24rem]"
                  src="/img/tobylinks/vector2.png"
                />
                <img
                  className="absolute z-0 w-[22rem] translate-x-[11rem] translate-y-[2.7rem] md:translate-x-[21rem]"
                  src="/img/tobylinks/mask_group2.png"
                />
                <img
                  className="absolute z-50 w-[34rem]"
                  src="/img/tobylinks/step5.png"
                />
              </div>
            </div>
          </TobyLinkStep>
        </section>
      </main>
    </Amplitude>
  );
};

export default TobyLinksHowToPage;

const TobyLinkStep = ({ children, ...props }) => {
  return (
    <div
      className="flex flex-col items-center gap-8 xl:container md:flex-row"
      {...props}
    >
      {children}
    </div>
  );
};

const TobyLinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="h-[32px] w-[32px] text-[#F65077]"
    fill="#F65077"
  >
    <g clipPath="url(#clip0_742_3160)">
      <path d="M17 7H14C13.45 7 13 7.45 13 8C13 8.55 13.45 9 14 9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H14C13.45 15 13 15.45 13 16C13 16.55 13.45 17 14 17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM8 12C8 12.55 8.45 13 9 13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11H9C8.45 11 8 11.45 8 12ZM10 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H10C10.55 9 11 8.55 11 8C11 7.45 10.55 7 10 7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H10C10.55 17 11 16.55 11 16C11 15.45 10.55 15 10 15Z" />
    </g>
    <defs>
      <clipPath id="clip0_742_3160">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);
