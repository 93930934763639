import React from 'react';

const Banner = ({bgCoverImg, bgColor, isBgCentered, isProduct}) => {
  const _bgCoverImg = bgCoverImg || '/img/product/product_header.svg';
  const _bgColor = bgColor || '#FFB6C9';
  const _bgPostion = isBgCentered ? 'center center' : 'top';
  const maxWidth = bgCoverImg ? 'maxW--med' : 'maxW--lg';
  return (
    <div className="img--bleed" style={{backgroundColor: _bgColor}}>
      {isProduct && <div className="Shadow-gradient o-4 pos--abs-top"></div>}
      <div className={`bgimg ${maxWidth}`} style={{
        backgroundImage: `url(${_bgCoverImg})`,
        backgroundPosition: _bgPostion
      }}></div>
    </div>
  )
}

export default Banner;