import React from "react";
import { Link } from "react-router-dom";
import { scrollTo } from "../helpers";
import { Amplitude } from "@amplitude/react-amplitude";

const Footer = () => (
  <div className="flex w-full justify-center">
    <div className="flex w-[1500px] flex-col-reverse items-center justify-center gap-10 bg-offwhite px-7 py-20 lg:flex-row lg:px-14">
      <div className="flex flex-col items-center gap-6 lg:mr-auto lg:items-start">
        <img
          width={33}
          height={33}
          alt="Toby"
          src="/img/logos/logo-symbol-pink.svg"
        />
        <p className="tneu800text-neutral-800 max-w-[500px] text-center text-base lg:text-left">
          All your documents, one-click away
        </p>
        <div className="flex gap-6">
          <Link
            className="font-light text-[#70708C] hover:underline"
            onClick={(e) => scrollTo("app", e)}
            to="/policy"
          >
            Privacy
          </Link>
          <Link
            className="font-light text-[#70708C] hover:underline"
            onClick={(e) => scrollTo("app", e)}
            to="/terms"
          >
            Terms
          </Link>
        </div>
      </div>
      {/* <a onClick={() => scrollTo("app")}>
      <img alt="arrow-up" className="Icon" src="/img/icons/arrowup.svg" />
    </a> */}
      <div className="flex gap-10 md:gap-20 lg:gap-24">
        <ul className="flex flex-col gap-6">
          <span className="text-xs font-bold uppercase text-neutral-800">
            Resources
          </span>
          <li className="text-base">
            <a
              className="text-neutral-800 hover:underline"
              href="https://help.gettoby.com/support/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Center
            </a>
          </li>
          <li className="text-base">
            <a
              className="text-neutral-800 hover:underline"
              href="https://feedback.gettoby.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Feedback
            </a>
          </li>
          <li className="text-base">
            <a
              className="text-neutral-800 hover:underline"
              href="https://feedback.gettoby.com/roadmap"
              target="_blank"
              rel="noopener noreferrer"
            >
              Roadmap
            </a>
          </li>
          <li className="text-base">
            <Amplitude eventProperties={{ scope: ["presskit"] }}>
              {({ logEvent }) => (
                <a
                  href="/files/presskit.zip"
                  onClick={() => logEvent("presskit")}
                  className="text-neutral-800 hover:underline"
                >
                  Press Kit
                </a>
              )}
            </Amplitude>
          </li>
          {/* <li className='text]'>
          <a
            href="https://help.gettoby.com/support/solutions/66000284888"
            target="_blank"
            rel="noopener"
          >
            Changelog
          </a>
        </li> */}
        </ul>
        <ul className="flex flex-col gap-6">
          <span className="text-xs font-bold uppercase text-neutral-800 hover:underline">
            Product
          </span>
          {/* <li className="text-base">
          <Link
            className="text-neutral-800 hover:underline"
            onClick={(e) => scrollTo("app", e)}
            to="/product"
          >
            Product
          </Link>
        </li> */}
          <li className="text-base">
            <Link
              className="text-neutral-800 hover:underline"
              onClick={(e) => scrollTo("app", e)}
              to="/pricing"
            >
              Pricing
            </Link>
          </li>
          <li className="text-base">
            <a
              className="text-neutral-800 hover:underline"
              href="https://web.gettoby.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Toby Web
            </a>
          </li>
          {/* <li className='text]'>
          <Amplitude eventProperties={{ scope: ["jobs"] }}>
            {({ logEvent }) => (
              <a
                href="https://www.axiomzen.co/careers"
                onClick={() => logEvent("jobs")}
                target="_blank"
                rel="noopener"
              >
                Careers
              </a>
            )}
          </Amplitude>
        </li> */}
        </ul>
        <ul className="flex flex-col gap-6">
          <span className="text-xs font-bold uppercase text-neutral-800 hover:underline">
            Socials
          </span>
          <li className="text-base">
            <a
              className="text-neutral-800 hover:underline"
              href="https://twitter.com/TobyForTabs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li className="text-base">
            <a
              className="text-neutral-800 hover:underline"
              href="https://discord.gg/swbqxAJCUD"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>
          </li>
          {/* <li className='text]'>
          <Link onClick={(e) => scrollTo("app", e)} to="/terms">
            Terms of Service
          </Link>
        </li> */}
          {/* <li className='text]'>
          <Link onClick={(e) => scrollTo("app", e)} to="/policy">
            Privacy Policy
          </Link>
        </li> */}
        </ul>
      </div>
      {/* <div className="Footer-socialWrapper">
      <Amplitude eventProperties={{ scope: ["twitter"] }}>
        {({ logEvent }) => (
          <a
            className="Footer-social Footer-social--twitter"
            href="https://twitter.com/TobyForTabs/"
            target="blank"
            onClick={() => logEvent("twitter")}
          ></a>
        )}
      </Amplitude>
      <Amplitude eventProperties={{ scope: ["facebook"] }}>
        {({ logEvent }) => (
          <a
            className="Footer-social Footer-social--facebook"
            href="https://www.facebook.com/TobyForTabs/"
            target="blank"
            onClick={() => logEvent("facebook")}
          ></a>
        )}
      </Amplitude>
    </div> */}
    </div>
  </div>
);

export default Footer;
