import React from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../core/Table";
import { Button } from "../core/Button";
import clsx from "clsx";
import { Plan } from "./Plans";
import { Skeleton } from "../Skeleton";
import { getBrowserCTA } from "../../helpers";

const { href } = getBrowserCTA();

const Check = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M5 12L10 17L20 7"
        stroke="#F65077"
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// const features = [
//   { name: "Core functionality", type: "header" },
//   {
//     name: "Saved tabs",
//     starter: "up to 40 saved tabs",
//     productivity: true,
//     team: true,
//   },
//   { name: "Collections", starter: true, productivity: true, team: true },
//   { name: "Members", starter: true, productivity: true, team: true },
//   { name: "Private Spaces", starter: true, productivity: true, team: true },
//   { name: "Public spaces", starter: true, productivity: true, team: true },
//   { name: "Productivity enhancements", type: "header" },
//   { name: "To/Links", starter: true, productivity: true, team: true },
//   { name: "Next", starter: true, productivity: true, team: true },
//   { name: "Search", starter: true, productivity: true, team: true },
//   { name: "Tags", starter: true, productivity: true, team: true },
//   { name: "Notes", starter: true, productivity: true, team: true },
//   {
//     name: "Public share collection",
//     starter: true,
//     productivity: true,
//     team: true,
//   },
//   { name: "Search advanced", starter: false, productivity: true, team: true },
//   {
//     name: "Remove duplicates",
//     starter: false,
//     productivity: true,
//     team: true,
//   },
//   { name: "Advanced and support", type: "header" },
//   { name: "Dark theme", starter: true, productivity: true, team: true },
//   {
//     name: "Organization themes",
//     starter: true,
//     productivity: true,
//     team: true,
//   },
//   { name: "Google sign on", starter: true, productivity: true, team: true },
//   {
//     name: "Priority support",
//     starter: false,
//     productivity: true,
//     team: true,
//   },
//   { name: "Permissions", starter: false, productivity: true, team: true },
//   { name: "SSO", starter: false, productivity: false, team: true },
//   {
//     name: "AI",
//     starter: "Coming soon",
//     productivity: "Coming soon",
//     team: "Coming soon",
//   },
// ];

// hack to get border spacing right
// for some reason adding `border-spacing` to the table wasn't working
const border =
  "after:absolute after:bottom-0 after:left-3 after:right-3 after:h-px after:bg-[#E6E9F5] after:content-['']";

export const PlanComparisonTable = (props: {
  plans: Plan[];
  features: unknown;
  className: string;
  isYearly: boolean;
  isLoading: boolean;
}) => {
  const { plans, isYearly, className, isLoading, features } = props;

  const getPriceId = (plan: Plan) => {
    if (isLoading) return undefined;
    if (isYearly && plan.yearlyPriceId) return plan.yearlyPriceId;
    if (!isYearly && plan.monthlyPriceId) return plan.monthlyPriceId;
    return "free";
  };

  return (
    <div className={clsx("container mx-auto px-4", className)}>
      <Table>
        <TableHeader>
          <TableRow className="border-none hover:bg-transparent">
            <TableHead
              className={clsx("relative h-auto w-1/4 text-left", border)}
            >
              <p className="mb-6 text-2xl font-semibold text-neutral-800">
                Compare plans
              </p>
              <p className="mb-6 text-sm text-gray-600">
                Upgrade for unlimited saved tabs and collections.
              </p>
            </TableHead>
            {(!isLoading ? plans : Array(3).fill({})).map(
              (plan: Plan, index) => (
                <TableHead
                  key={plan.name ?? index}
                  className={clsx(
                    "relative h-auto w-1/4 px-7 text-left",
                    border
                  )}
                >
                  {isLoading ? (
                    <Skeleton className="mb-7 h-8 w-48" />
                  ) : (
                    <h3 className="mb-7 text-lg font-semibold text-neutral-800 lg:text-2xl">
                      {plan.name?.replace("plan", "")}
                    </h3>
                  )}

                  {!isLoading ? (
                    <div className="mb-7 flex flex-col items-start gap-x-2 xl:flex-row xl:items-end">
                      {!plan.monthlyPrice ? (
                        <>
                          <span className="text-2xl font-bold text-neutral-800 lg:text-4xl">
                            Free
                          </span>
                          <span className="h-10 lg:h-6 xl:h-5"></span>
                        </>
                      ) : (
                        <>
                          <span className="text-2xl font-bold text-neutral-800 lg:text-4xl">
                            {"$ "}
                            {isYearly ? plan.yearlyPrice : plan.monthlyPrice}
                          </span>
                          {plan.yearlyPrice !== undefined && (
                            <span className="text-sm font-normal text-gray-600 lg:mb-1">
                              per member / month
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <Skeleton className="mb-7 h-10 w-20" />
                  )}

                  <Button
                    as="a"
                    href={href}
                    role="button"
                    className="js-Button--checkout mb-8"
                    data-price-id={getPriceId(plan)}
                    variant={
                      plan.monthlyPrice === undefined ? "secondary" : "primary"
                    }
                    size="full"
                  >
                    {plan.monthlyPrice === undefined
                      ? "GET STARTED"
                      : "UPGRADE"}
                  </Button>
                </TableHead>
              )
            )}
          </TableRow>
        </TableHeader>
        <TableBody>
          {features.map((feature, index) => (
            <TableRow
              key={index}
              className={clsx(
                "border-0",
                feature.type === "header" && "hover:bg-transparent"
              )}
            >
              {feature.type === "header" ? (
                <TableCell
                  colSpan={4}
                  className="pt-12 font-semibold text-neutral-800"
                >
                  {feature.name}
                </TableCell>
              ) : (
                <>
                  <TableCell
                    className={clsx("relative text-neutral-800", border)}
                  >
                    {feature.name}
                  </TableCell>
                  <TableCell
                    className={clsx("relative text-neutral-800", border)}
                  >
                    {feature.starter?.available ? (
                      <Check className="mr-1 inline text-brand-300" />
                    ) : null}
                    {feature.starter?.content}
                  </TableCell>
                  <TableCell
                    className={clsx("relative text-neutral-800", border)}
                  >
                    {feature.productivity?.available ? (
                      <Check className="mr-1 inline text-brand-300" />
                    ) : null}
                    {feature.productivity?.content}
                  </TableCell>
                  <TableCell
                    className={clsx("relative text-neutral-800", border)}
                  >
                    {feature.team?.available ? (
                      <Check className="mr-1 inline text-brand-300" />
                    ) : null}
                    {feature.team?.content}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
