import React from 'react';
import {isMobile} from '../constants';
import {getBrowserCTA} from '../helpers';
const {browser, href} = getBrowserCTA()

const Hero = ({heading, subtitle, showButton}) => {
  let header;
  let subheader;
  let toggle;
  let margin;
  let padding = 'p--all'

  switch(heading) {
    case ('Better Than Bookmarks'):
      margin = 'm--ver-large-home';
      header = <h1 className="align--center text-neutral-800">{heading}</h1>
      subheader = 
        <h5 className="w--normal p--ver text-neutral-800 maxW-xxs">
          Bookmarks are for books, not browsers. Organize your browser tabs into Toby so you can access key resources in one click instead of seven.
        </h5>
      break;
    
    case ('Workspace in your Browser'):
      margin = 'm--ver-large';
      header = <h2 className="align--center text-neutral-800">{heading}</h2>
      subheader = 
        <h5 className="w--normal p--ver text-neutral-800 maxW-xxs">
          You and your team works in a certain way.<br/>Toby is a collaborative workspace platform that makes that work happen.
        </h5>
      break;

    case ('Plans and Pricing'):
      margin = '';
      padding = 'p--ver-med p--hor';
      header = <h4 className="align--center text-neutral-800">{heading}</h4>
      subheader = 
        <h6 className="w--normal p--all text-neutral-800">
          Toby for Teams is a single workspace for your small-to-medium size company or team.
        </h6>
      // toggle =
      //   <div className="Prices-toggle">
      //     <p>Annual</p>
      //     <p>Monthly</p>
      //   </div>
      break;
      
    default :
      margin = '';
      padding = 'p--top-med p--hor p--bot';
      header = <h4 className="align--center text-neutral-800">{heading}</h4>
      subheader = 
      <h6 className="w--bold text-neutral-800">
        {subtitle}
      </h6>
      break;
  }
  return (
  <div className="g g-j-i-center">
    <div className={`g g-i-center g-gap-1 t-center ${padding} ${margin} maxW--sm`}>
      {header}
      {subheader}
      {toggle && toggle}
      {!isMobile && showButton && (
        <a className="Button Button--large Button--pinkShadow Button--noHoverChange Button--addToby js-Button--install Button--hide" href={href} target="_blank" rel="noopener">Add Toby to {browser}</a>
      )}
    </div>
  </div>
)}

export default Hero;
