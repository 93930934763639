import React, { useState } from "react";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import { Player } from "@lottiefiles/react-lottie-player";
import checkmarkLottie from "../img/lottie/checkmark";
import { useLocation } from "react-router-dom";
import Config from "../../../config.js";
import useOnMount from "../hooks/useOnMount";

// Link was success
const STATE_SUCCESS = "STATE_SUCCESS";
// Link was invalid (expired, mismatch token)
const STATE_LINK_ERROR = "STATE_LINK_ERROR";
// Link does not have token or email params
const STATE_INVALID_LINK = "STATE_INVALID_LINK";

function VerifyEmail() {
  return (
    <div className="Header-Margin--offset">
      <Amplitude
        eventProperties={{
          scope: ["Verify Email"],
        }}
      >
        <LogOnMount eventType="Verify Email" />
        <VerifyEmailStates />
      </Amplitude>
    </div>
  );
}

function VerifyEmailStates() {
  const searchParams = new URLSearchParams(useLocation().search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const isValidLink = email && token;
  const [status, setStatus] = useState(isValidLink ? "" : STATE_INVALID_LINK);
  const isProduction = window.location.origin === "https://www.gettoby.com";
  const apiUrl = isProduction
    ? "https://api2.gettoby.com/v2"
    : "https://api-staging2.gettoby.com/v2";

  useOnMount(handleVerifyAccount);

  async function handleVerifyAccount() {
    if (!isValidLink) {
      return;
    }
    try {
      await fetch(`${apiUrl}/users/verify?email=${email}&token=${token}`);
      setStatus(STATE_SUCCESS);
    } catch (e) {
      setStatus(STATE_LINK_ERROR);
    }
  }

  async function handleResendVerification() {
    await fetch(`${apiUrl}/users/send-verification`, {
      headers: {
        "x-auth-token": token,
      },
    });
  }

  if (status === STATE_SUCCESS) {
    return (
      <div className="g g-row g-gap-2 g-j-i-center p--hor p--top-lg p--bot-xxl maxW--xs">
        <h4 className="c--darkGrey w--bold-x t-center">Email Verified</h4>
        <Player autoplay src={checkmarkLottie} keepLastFrame />
        <p className="success t-center">Success!</p>
        <p className="c--light-black t-center">
          Your email address has been successfully verified! <br />
          Thanks for taking the time to do this. You're now all set to use all
          of Toby
        </p>

        <a href="/open-toby">
          <button className="Button--rect fs--16">OPEN TOBY</button>
        </a>
        <p className="fs--16 c--light-black t-center">
          Have an issue? email us at{" "}
          <a href="mailto:hello@gettoby.com">hello@gettoby.com</a>
        </p>
      </div>
    );
  }

  if (status === STATE_LINK_ERROR) {
    return (
      <div className="g g-row g-gap-2 g-j-i-center p--hor p--top-lg p--bot-xxl maxW--xs">
        <h4 className="c--warning w--bold-x t-center">The link has expired</h4>
        <div>
          <p className="c--darkGrey t-center fs--20">
            Verifications links are only valid for 24 hours.
            <br />
            Need a new verification link?
          </p>
        </div>
        <button
          className="Button--rect m--top-med fs--16"
          onClick={handleResendVerification}
        >
          RESEND LINK
        </button>
        <p className="fs--16 c--light-black t-center">
          Have an issue? email us at{" "}
          <a href="mailto:hello@gettoby.com">hello@gettoby.com</a>
        </p>
      </div>
    );
  }

  // @todo: Fill in with appropriate UI
  if (status === STATE_INVALID_LINK) {
    return (
      <div className="g g-row g-gap-2 g-j-i-center p--hor p--top-lg p--bot-xxl maxW--xs">
        <h4 className="c--warning w--bold-x t-center">The link is invalid</h4>
        <div>
          <p className="c--darkGrey t-center fs--20">
            {!email && "Missing email"}
            <br />
            {!token && "Missing token"}
          </p>
        </div>
      </div>
    );
  }
  return null;
}

export default VerifyEmail;
