import React from 'react';

const SignupEmail = ({ errorMessage, email, setEmail, handleSubmitSkip6, handleSubmit, isValid, selected }) => (
  <div id="createEmail" className="Create-email Create-flowBlock">
    <form className="Create-maxWidth" onSubmit={handleSubmit}>
      <p>Your work email</p>
      <div className={`Create-errorAccount${errorMessage ? ' Create-error' : ''}`}>
        <p className="Create-errorLabel">{errorMessage}</p>
      </div>
      <div className={isValid ? '' : 'Create-error'}>
        <p className="Create-errorLabel">Must be a valid email address</p>
        <input autoFocus={selected ? 'autofocus' : ''} onChange={e => setEmail(e.target.value)} value={email} className="js-validate-required js-validate-email js-trigger Form-input Form-input--dark" type='email' name='email' placeholder='you@company.com'></input>
      </div>
      <div className="Create-nextButton">
        <button className="js-trigger-target Button Button--secondary Button--large" onClick={handleSubmit}>Next</button>
        {!email ? (
          <a className="Create-link" onClick={handleSubmitSkip6}>I already have an account</a>
        ) : (
          null
        )}
      </div>
    </form>
  </div>
)

export default SignupEmail;