import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import {
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";
import { sanitizeEmail } from '../helpers';

const STATE_SUCCESS = 'STATE_SUCCESS';

const ERROR_MISMATCH_EMAIL = 'ERROR_MISMATCH_EMAIL';
const ERROR_LENGTH_PASSWORD = 'ERROR_LENGTH_PASSWORD';

const ERROR_EXPIRED_TOKEN = 'ERROR_EXPIRED_TOKEN';
const ERROR_INVALID_TOKEN = 'ERROR_INVALID_TOKEN';
const ERROR_MISMATCH_TOKEN = 'ERROR_MISMATCH_TOKEN';
const ERROR_CONSUMED_TOKEN = 'ERROR_CONSUMED_TOKEN';

function ResetPassword() {
  return (
    <div className="Header-Margin--offset">
      <div className="Shadow-gradient o-4"></div>
      <Amplitude
        eventProperties={{
          scope: ["Reset Password"]
        }}>
        <LogOnMount eventType="Reset Password" />
        <ResetPasswordStates />
      </Amplitude>
    </div>
  )
}

function ResetPasswordStates() {
  const [status, setStatus] = useState(null)

  const [email, setEmail] = useState('');

  switch (status) {
    case (STATE_SUCCESS):
      return <Success />
    case (ERROR_EXPIRED_TOKEN):
    case (ERROR_INVALID_TOKEN):
    case (ERROR_MISMATCH_TOKEN):
    case (ERROR_CONSUMED_TOKEN):
      return <Error email={email} status={status} />
    default:
      return <Form email={email} setEmail={setEmail} setStatus={setStatus} />
  }
}

function Form({ setStatus, email, setEmail }) {
  const [password, setPassword] = useState('');

  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [additionalErrors, setAdditionalErrors] = useState(null)

  const [isSubmitResetBtnDisabled, setIsSubmitResetBtnDisabled] = useState(false)

  const searchParams = new URLSearchParams(useLocation().search)

  const emailParams = searchParams.get('email');
  const tokenParams = searchParams.get('token');

  const resetPassword = function (payload) {
    const isProduction = window.location.origin === 'https://www.gettoby.com'
    const TOBY_API = isProduction ? 'https://api2.gettoby.com/v2' : 'https://api-staging2.gettoby.com/v2'
    const request = new Request(`${TOBY_API}/users/password`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(payload)
    });
    return fetch(request)
  };

  const cleanupForm = () => {
    setIsEmailInvalid(false);
    setIsPasswordInvalid(false);
    setIsSubmitResetBtnDisabled(true)
  }

  const handleSubmitReset = async (e) => {
    e.preventDefault();
    cleanupForm();
    // const isInvalidEmail = emailParams.trim().toLowerCase() !== email.trim().toLowerCase();
    const isInvalidPassword = password.length < 6;

    // if (isInvalidEmail) {
    //   setIsEmailInvalid(true)
    // }
    if (isInvalidPassword) {
      setIsPasswordInvalid(true)
    }
    // if (isInvalidEmail || isInvalidPassword) {
    if (isInvalidPassword) {
      setIsSubmitResetBtnDisabled(false)
      return;
    }

    try {
      const res = await resetPassword({
        email: sanitizeEmail(email),
        password: password,
        token: tokenParams
      })

      if (res.status === 500) {
        setAdditionalErrors('Sorry, we are experiencing some difficulties. Please try again in a few minutes!')
        return;
      }
      if (res.status === 200) {
        setStatus(STATE_SUCCESS)
        return;
      }

      const resp = await res.json();
      const statusMsg = resp.message;
      switch (statusMsg) {
        case (ERROR_MISMATCH_EMAIL): {
          setIsEmailInvalid(true)
          return;
        }
        case (ERROR_LENGTH_PASSWORD): {
          setIsPasswordInvalid(true)
          return;
        }
        case (ERROR_EXPIRED_TOKEN):
        case (ERROR_INVALID_TOKEN):
        case (ERROR_MISMATCH_TOKEN):
        case (ERROR_CONSUMED_TOKEN): {
          setStatus(statusMsg)
          return;
        }
      }
    } catch (err) {
      console.warn('Error:', err)
      setAdditionalErrors('Sorry it looks like there\'s a network error')
    } finally {
      setIsSubmitResetBtnDisabled(false)
    }
  }

  return (
    <form onSubmit={handleSubmitReset} className="g g-row g-gap-2 g-j-i-center p--hor p--top-lg p--bot-xxl maxW--xs">
      <h4 className="c--brand w--bold-x t-center">Reset Toby Password</h4>
      <p className="c--light-black t-center">
        Type the email used when you signed up for Toby and your new password.
      </p>
      {additionalErrors && <p className="error">{additionalErrors}</p>}
      <div className="g g-row j-i">
        <input type='email' placeholder="Your email" value={email} onChange={e => setEmail(e.target.value)} className={`text-field ${isEmailInvalid ? 'text-field--invalid' : ''}`} />
        {isEmailInvalid && <label className="m--top-sm error">Invalid Email</label>}
      </div>
      <div className="g g-row j-i">
        <input type='password' placeholder="Your new password" value={password} onChange={e => setPassword(e.target.value)} className={`text-field ${isPasswordInvalid ? 'text-field--invalid' : ''}`} />
        <label className={`m--top-sm ${isPasswordInvalid ? 'error' : ''}`}>Must be at least 6 characters long</label>
      </div>
      <p className="c--light-black t-center fs--18">Have an issue? Email us at <a href="mailto:hello@gettoby.com">hello@gettoby.com</a></p>
      <button className="Button--rect" onClick={handleSubmitReset} disabled={isSubmitResetBtnDisabled}>Reset password</button>
    </form>
  )
}

function Success() {
  return (
    <div className="g g-row g-gap-2 g-j-i-center p--hor p--top-lg p--bot-xxl maxW--xs">
      <h4 className="c--brand w--bold-x t-center">Reset Toby Password</h4>
      <img src='/img/signup/checkmark.png' />
      <p className="success t-center">
        Success!
      </p>
      <p className="c--light-black t-center">
        Your password has been reset. Go back to the extension to sign in.
      </p>

      <button className="Button--rect" onClick={() => window.open('/open-toby', '_blank')}>Open new tab</button>
    </div>
  )
}

function Error({ email, status }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleReset = () => {
    const isProduction = window.location.origin === 'https://www.gettoby.com'
    const TOBY_API = isProduction ? 'https://api2.gettoby.com/v2' : 'https://api-staging.gettoby.com/v2'
    const request = new Request(`${TOBY_API}/users/reset`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ email })
    });
    return fetch(request)
  }
  
  const handleSubmissionResetEmail = async() => {
    try {
      await handleReset()
      console.log('Email has been sent to resubmit password')
      setIsButtonDisabled(true)
    } catch (err) {
      console.warn('Email has not been sent for reset password', err)
    }
  }

  const ERROR_STATUSES = {
    [ERROR_EXPIRED_TOKEN]: {
      header: 'The link has expired',
      subheader: "Password reset links are only vaild for 24 hours. Would you like us to send you a new reset password link?",
      cta: handleSubmissionResetEmail,
      ctaText: 'Resend email',
      ctaDisabledBtnText: 'SENT!',
      ctaSubmissionText: `Please check your email, ${email}`
    },
    [ERROR_INVALID_TOKEN]: {
      header: 'Uhoh, an occured with the link',
      subheader: 'We were unable to change your password, please try again.',
      cta: () => window.location.reload(),
      ctaText: 'Try again',
      ctaDisabledBtnText: 'Try again',
      ctaSubmissionText: null
    },
    [ERROR_MISMATCH_TOKEN]: {
      header: 'Invalid link',
      subheader: "This is not the latest reset password link or this link has already been used. Need a new reset password link?",
      cta: handleSubmissionResetEmail,
      ctaText: 'Resend email',
      ctaDisabledBtnText: 'SENT!',
      ctaSubmissionText: `Please check your email, ${email}`
    },
    [ERROR_CONSUMED_TOKEN]: {
      header: 'Invalid link',
      subheader: "This is not the latest reset password link or this link has already been used. Would you like us to send you a new reset password link?",
      cta: handleSubmissionResetEmail,
      ctaText: 'Resend email',
      ctaDisabledBtnText: 'SENT!',
      ctaSubmissionText: `Please check your email, ${email}`
    }
  }
  
  const {header, subheader, cta, ctaText, ctaDisabledBtnText, ctaSubmissionText} = ERROR_STATUSES[status]

  const btnText = isButtonDisabled ? ctaDisabledBtnText : ctaText
  const isSubmissionTextDisplayed = isButtonDisabled && ctaSubmissionText

  const isStatusInvalidToken = status === ERROR_INVALID_TOKEN

  return (
    <div className="g g-row g-gap-2 g-j-i-center p--hor p--top-lg p--bot-xxl maxW--xs">
      <h4 className="error w--bold-x t-center">{header}</h4>
      <p className="error t-center">{subheader}</p>
      <button className="Button--rect" onClick={cta} disabled={isButtonDisabled}>{btnText}</button>
      {isSubmissionTextDisplayed && <p className="c--light-black">{ctaSubmissionText}</p>}
      {isStatusInvalidToken && <p className="c--light-black t-center fs--18">Still blocked? Email us at <a href="mailto:hello@gettoby.com">hello@gettoby.com</a></p>}
    </div>
  )
}

export default ResetPassword;
