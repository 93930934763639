import React, {useState} from 'react';

const FAQCard = ({question}) => {
  const [display, setDisplay] = useState(false)
  return (
    <div className="f-col cursor--pointer" onClick={() => setDisplay(!display)}>
      <span className={`arrow ${display ? 'arrow-toggle': ''}`} ></span>
      <span>
        <h6 className={`w--bold-x ${display ? 'c--brand': 'c--dark-grey'}`}>{question.title}</h6>
        {display ? (
          <p className="w--medbold p--top-small" dangerouslySetInnerHTML={{__html: question.description}}></p>
        ) : ''}
      </span>
    </div>
  )
};


export default FAQCard;