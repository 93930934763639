import React from 'react';
import Cookies from 'js-cookie';

export async function authenticateUser({ email, password }) {
    return fetch("/users/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            password: password,
        }),
    })
        .then((res) => res.json())
        .then((data) => {
            localStorage.setItem("authToken", data.token);
            localStorage.setItem("setupAt", new Date().getTime());
            setAuthToken(data.token);
            return data;
        });
}

export function requireAuth(WrappedComponent) {
    return function (props) {
        if (isAuthenticated()) {
            return <WrappedComponent {...props} />;
        } else {
            return <Redirect to="/login" />;
        }
    };
}

export function isAuthenticated() {
    return getAuthToken() !== null;
}

export function getAuthToken() {
    // window.token is injected by the extension
    return window.token || localStorage.getItem("authToken");
}

export function setAuthToken(token) {
    localStorage.setItem("authToken", token);
    localStorage.setItem("setupAt", new Date().getTime());
    Cookies.set("tobySession", token, { expires: 365, path: '/' });
}
