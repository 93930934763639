import React from "react";
import { cva, type VariantProps } from "cva";
import { cn } from "../../helpers";
import { PolymorphicComponentPropWithRef, PolymorphicRef } from "./types";

const button = cva(
  [
    "rounded-[5px]",
    "px-4",
    "py-2.5",
    "flex",
    "justify-center",
    "items-center",
    "relative",
    "font-sans",
    "font-semibold",
    "box-border",
    "transition-colors",
    "whitespace-nowrap",
    "uppercase",
    "cursor-pointer",
  ],
  {
    variants: {
      variant: {
        primary: [
          "text-white",
          "border",
          "border-brand-300",
          "bg-brand-300",
          // hover
          "hover:bg-brand-400",
          "hover:border-brand-400",
          // disabled
          "disabled:text-neutral-700",
          "disabled:bg-neutral-100",
          "disabled:border-neutral-700",
          "disabled:border-[1px]",
          "disabled:border-solid",
        ],
        secondary: [
          "text-brand-300",
          "border",
          "border-solid",
          "border-brand-300",
          "hover:bg-brand-300",
          "hover:text-white",
          // disabled
          "disabled:text-neutral-700",
          "disabled:bg-neutral-100",
          "disabled:border-neutral-700",
        ],
        tertiary: [
          "border",
          "border-transparent",
          "text-brand-300",
          "hover:bg-brand-100",
          "hover:text-brand-400",
          // disabled
          "disabled:text-neutral-700",
        ],
      },
      size: {
        sm: ["w-min", "text-xs"],
        md: ["w-min", "text-sm"],
        lg: ["w-min", "text-md"],
        full: ["w-full", "text-sm"],
      },
    },
    compoundVariants: [{ variant: "primary", size: "md", class: "uppercase" }],
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  }
);

type ButtonVariants = VariantProps<typeof button>;

type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  ButtonVariants & {
    className?: string;
    variant?: "primary" | "secondary" | "tertiary";
    size?: "sm" | "md" | "lg" | "full";
  }
>;

type ButtonComponent = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactElement | null;

export const Button = React.forwardRef(
  <C extends React.ElementType = "button">(
    { as, className, variant, size = "md", ...props }: ButtonProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || "button";
    return (
      <Component
        className={cn(button({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  }
) as ButtonComponent;

Button.displayName = "Button";
