import React from "react";
import clsx from "clsx";
import { Button } from "../core/Button";
import { Skeleton } from "../Skeleton";
import { getBrowserCTA } from "../../helpers";

const { href } = getBrowserCTA();

export const PlanCard = (props: {
  className?: string;
  name: string;
  desc: string;
  monthlyPrice?: number;
  yearlyPrice?: number;
  yearlyDiscount?: number;
  features: string[];
  isYearly: boolean;
  isLoading?: boolean;
  yearlyPriceId?: string;
  monthlyPriceId?: string;
  id: string;
}) => {
  const {
    className,
    name,
    desc,
    monthlyPrice,
    yearlyPrice,
    yearlyDiscount,
    features,
    isYearly,
    isLoading,
    yearlyPriceId,
    monthlyPriceId,
    id,
  } = props;

  const getPriceId = () => {
    if (isLoading) return undefined;
    if (isYearly && yearlyPriceId) return yearlyPriceId;
    if (!isYearly && monthlyPriceId) return monthlyPriceId;
    return "free";
  };

  return (
    <div
      className={clsx(
        "relative flex flex-col rounded-[20px] bg-white px-6 py-8 shadow-[0px_2px_15px_0px_rgba(112,_112,_140,_0.25)]",
        id === "ProductivityPlan" &&
          "border-[2px] border-solid border-[#FFCFD5]",
        className
      )}
    >
      {id === "ProductivityPlan" && (
        <div className="absolute -top-[18px] right-1/2 flex h-[36px] w-[134px] translate-x-1/2 items-center justify-center rounded-[10px] border border-solid border-[#FFCFD5] bg-[#FFF0F1] shadow-[1px_2px_4px_0px_rgba(112,_112,_140,_0.15)]">
          <span className="text-xs font-semibold uppercase text-[#CF3A61]">
            {isYearly ? "Best deal" : "Unlimited"}
          </span>
        </div>
      )}
      {!isLoading ? (
        <div className="mb-2 flex items-center justify-between">
          <span className="text-2xl font-semibold text-neutral-800">
            {name?.replace("plan", "")}
          </span>
          {monthlyPrice !== undefined && yearlyDiscount && isYearly && (
            <span className="flex items-center gap-1.5 rounded-[10px] bg-[#DDFFEF] px-2 py-1.5 text-xs font-semibold uppercase text-success-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M5.99845 9.00004L7.66512 10.6667L10.9985 7.33337M2.6651 5.00002C2.6651 4.51379 2.85825 4.04747 3.20207 3.70366C3.54589 3.35984 4.0122 3.16668 4.49843 3.16668H5.33176C5.81585 3.16641 6.28017 2.97469 6.62343 2.63335L7.20676 2.05002C7.37714 1.87868 7.5797 1.74272 7.8028 1.64994C8.0259 1.55716 8.26514 1.5094 8.50676 1.5094C8.74839 1.5094 8.98763 1.55716 9.21073 1.64994C9.43383 1.74272 9.63639 1.87868 9.80676 2.05002L10.3901 2.63335C10.7334 2.97502 11.1984 3.16668 11.6818 3.16668H12.5151C13.0013 3.16668 13.4676 3.35984 13.8115 3.70366C14.1553 4.04747 14.3484 4.51379 14.3484 5.00002V5.83335C14.3484 6.31668 14.5401 6.78168 14.8818 7.12502L15.4651 7.70835C15.6364 7.87872 15.7724 8.08128 15.8652 8.30439C15.958 8.52749 16.0057 8.76673 16.0057 9.00835C16.0057 9.24998 15.958 9.48921 15.8652 9.71232C15.7724 9.93542 15.6364 10.138 15.4651 10.3084L14.8818 10.8917C14.5404 11.2349 14.3487 11.6993 14.3484 12.1834V13.0167C14.3484 13.5029 14.1553 13.9692 13.8115 14.313C13.4676 14.6569 13.0013 14.85 12.5151 14.85H11.6818C11.1977 14.8503 10.7334 15.042 10.3901 15.3834L9.80676 15.9667C9.63639 16.138 9.43383 16.274 9.21073 16.3668C8.98763 16.4595 8.74839 16.5073 8.50676 16.5073C8.26514 16.5073 8.0259 16.4595 7.8028 16.3668C7.5797 16.274 7.37714 16.138 7.20676 15.9667L6.62343 15.3834C6.28017 15.042 5.81585 14.8503 5.33176 14.85H4.49843C4.0122 14.85 3.54589 14.6569 3.20207 14.313C2.85825 13.9692 2.6651 13.5029 2.6651 13.0167V12.1834C2.66482 11.6993 2.4731 11.2349 2.13176 10.8917L1.54843 10.3084C1.3771 10.138 1.24113 9.93542 1.14835 9.71232C1.05557 9.48921 1.00781 9.24998 1.00781 9.00835C1.00781 8.76673 1.05557 8.52749 1.14835 8.30439C1.24113 8.08128 1.3771 7.87872 1.54843 7.70835L2.13176 7.12502C2.4731 6.78176 2.66482 6.31743 2.6651 5.83335V5.00002Z"
                  stroke="#36BF81"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Save {yearlyDiscount}
            </span>
          )}
        </div>
      ) : (
        <Skeleton className="mb-2 h-8 w-[200px]" />
      )}

      {!isLoading ? (
        <span className="mb-8 min-h-[40px] text-sm font-normal text-neutral-700">
          {desc}
        </span>
      ) : (
        <>
          <Skeleton className="mb-1 h-4 w-[300px]" />
          <Skeleton className="mb-9 h-4 w-[280px] opacity-50" />
        </>
      )}

      {!isLoading ? (
        <div className="mb-2 flex items-end gap-1">
          {!monthlyPrice ? (
            <span className="text-[54px] font-bold leading-[54px] text-neutral-800">
              Free
            </span>
          ) : (
            <>
              <span className="text-[54px] font-bold leading-[54px] text-neutral-800">
                {"$ "}
                {isYearly ? yearlyPrice : monthlyPrice}
              </span>
              <span className="mb-1 text-sm font-medium uppercase text-neutral-600">
                usd
              </span>
            </>
          )}
        </div>
      ) : (
        <Skeleton className="mb-2 h-[54px] w-[100px]" />
      )}

      {!isLoading ? (
        !monthlyPrice && !yearlyPrice ? (
          <div className="flex-grow" />
        ) : (
          <>
            <span className="text-sm font-semibold text-neutral-700">
              per member / month
            </span>
            {isYearly && yearlyPrice ? (
              <span
                className={clsx(
                  "mt-1 text-sm font-normal text-neutral-600",
                  isYearly ? "visible" : "invisible"
                )}
              >
                billed yearly ($
                {yearlyPrice * 12}
                /year)
              </span>
            ) : (
              <div className="mt-1 h-5" />
            )}
          </>
        )
      ) : (
        <>
          <Skeleton className="h-5 w-[160px]" />
          <Skeleton className="mt-1 h-5 w-[160px] opacity-50" />
        </>
      )}

      <Button
        as="a"
        href={href}
        data-price-id={getPriceId()}
        role="button"
        className="js-Button--checkout my-7"
        variant={monthlyPrice ? "primary" : "secondary"}
        size="full"
      >
        {monthlyPrice ? "UPGRADE" : "GET STARTED"}
      </Button>

      <div className="flex min-h-[160px] flex-col gap-2">
        {!isLoading
          ? Array.isArray(features)
            ? features.map((feature, index) => (
                <div key={index} className="flex items-start gap-2">
                  {!monthlyPrice ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      className="min-h-[21px] min-w-[21px]"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        d="M8 10.5L9.66667 12.1667L13 8.83333M3 10.5C3 11.4849 3.19399 12.4602 3.5709 13.3701C3.94781 14.2801 4.50026 15.1069 5.1967 15.8033C5.89314 16.4997 6.71993 17.0522 7.62987 17.4291C8.53982 17.806 9.51509 18 10.5 18C11.4849 18 12.4602 17.806 13.3701 17.4291C14.2801 17.0522 15.1069 16.4997 15.8033 15.8033C16.4997 15.1069 17.0522 14.2801 17.4291 13.3701C17.806 12.4602 18 11.4849 18 10.5C18 9.51509 17.806 8.53982 17.4291 7.62987C17.0522 6.71993 16.4997 5.89314 15.8033 5.1967C15.1069 4.50026 14.2801 3.94781 13.3701 3.5709C12.4602 3.19399 11.4849 3 10.5 3C9.51509 3 8.53982 3.19399 7.62987 3.5709C6.71993 3.94781 5.89314 4.50026 5.1967 5.1967C4.50026 5.89314 3.94781 6.71993 3.5709 7.62987C3.19399 8.53982 3 9.51509 3 10.5Z"
                        stroke="#F65077"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M14.168 2.78455C15.4249 3.51026 16.4704 4.55165 17.2011 5.80563C17.9319 7.05961 18.3224 8.48269 18.3342 9.934C18.3459 11.3853 17.9784 12.8145 17.268 14.0801C16.5576 15.3457 15.529 16.4039 14.284 17.1498C13.039 17.8957 11.6208 18.3036 10.1697 18.3329C8.71866 18.3623 7.28509 18.0122 6.01093 17.3173C4.73677 16.6223 3.66619 15.6067 2.90519 14.3708C2.14419 13.135 1.71914 11.7218 1.67214 10.2712L1.66797 10.0012L1.67214 9.73121C1.7188 8.29204 2.13759 6.88951 2.88767 5.66037C3.63774 4.43123 4.69351 3.41742 5.95205 2.71778C7.21058 2.01814 8.62892 1.65654 10.0688 1.66824C11.5087 1.67994 12.921 2.06454 14.168 2.78455ZM13.0905 7.74538C12.947 7.6019 12.7561 7.51571 12.5535 7.50297C12.351 7.49024 12.1508 7.55184 11.9905 7.67621L11.9121 7.74538L9.16797 10.4887L8.09047 9.41205L8.01214 9.34288C7.85179 9.21859 7.65161 9.15707 7.44914 9.16984C7.24667 9.18261 7.05581 9.26881 6.91235 9.41226C6.7689 9.55572 6.6827 9.74658 6.66993 9.94905C6.65716 10.1515 6.71868 10.3517 6.84297 10.512L6.91214 10.5904L8.5788 12.257L8.65714 12.3262C8.80328 12.4396 8.983 12.5011 9.16797 12.5011C9.35294 12.5011 9.53266 12.4396 9.6788 12.3262L9.75714 12.257L13.0905 8.92371L13.1596 8.84538C13.284 8.68504 13.3456 8.48483 13.3329 8.28231C13.3201 8.07979 13.2339 7.88887 13.0905 7.74538Z"
                        fill="#F65077"
                      />
                    </svg>
                  )}
                  <span className="text-sm font-normal text-neutral-700">
                    {feature}
                  </span>
                </div>
              ))
            : null
          : Array(6)
              .fill({})
              .map((_, index) => (
                <div key={index} className="flex items-start gap-2">
                  <Skeleton className="h-5 w-5 rounded-full" />
                  {index === 0 ? (
                    <Skeleton className="h-5 w-[160px]" />
                  ) : index === 1 ? (
                    <Skeleton className="h-5 w-[200px] opacity-80" />
                  ) : index === 2 ? (
                    <Skeleton className="h-5 w-[180px] opacity-60" />
                  ) : index === 3 ? (
                    <Skeleton className="h-5 w-[220px] opacity-40" />
                  ) : index === 4 ? (
                    <Skeleton className="h-5 w-[200px] opacity-20" />
                  ) : (
                    <Skeleton className="h-5 w-[180px] opacity-10" />
                  )}
                </div>
              ))}
      </div>
    </div>
  );
};
