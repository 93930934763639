import React from "react";
import { Link } from "react-router-dom";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import { scrollTo } from "../helpers";

const TermsOfServices = () => {
  return (
    <div className="Header-Margin--offset">
      <Amplitude
        eventProperties={{
          scope: ["terms-of-service"],
        }}
      >
        <LogOnMount eventType="terms-of-service" />
        <div className="g g-j-i-center g-gap-4 maxW--sm p--ver-med p--hor Policy-Container">
          <div className="g g-gap-1">
            <h4 className="text-neutral-900">Terms of Service</h4>
            <p className="text-lg text-neutral-600">
              Last Updated: July 18, 2023
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">1. Acceptance of Terms</h6>
            <p>
              Toby is made available to you subject to your acceptance of these
              Terms (as defined below) via the website,{" "}
              <a
                href="https://www.gettoby.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.gettoby.com
              </a>{" "}
              (the “Site”), or via a Chrome extension (the “Extension”). The
              Site, the Extension, and any other software, add-ons,
              applications, features, tools, materials, or other services
              offered from time to time in relation to the Site or the Extension
              are collectively referred to here as the “Service.”
            </p>
            <p>
              Launch Labs, Inc. dba Axiom Zen ("Axiom Zen", "we", or "us") is
              making the Service available to you. Before you use any part of
              the Service, however, you will need to agree to these Terms of
              Service and any terms and conditions incorporated herein by
              reference (collectively, these “Terms”), including, without
              limitation,{" "}
              <Link onClick={(e) => scrollTo("app", e)} to="/policy">
                Toby’s Privacy Policy
              </Link>
              .
            </p>
            <p>
              You acknowledge and agree that the Service is made available
              primarily for business use and not intended primarily for
              personal, family or household use or otherwise for consumer use.{" "}
            </p>
            <p>
              PLEASE READ THESE TERMS CAREFULLY BEFORE USING ANY PART OF THE
              SERVICE. THESE TERMS GOVERN YOUR USE OF THE SERVICE, UNLESS WE
              HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT
              PURPOSE. AXIOM ZEN IS ONLY WILLING TO MAKE THE SERVICE AVAILABLE
              TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY USING ANY PART OF THE
              SERVICE, OR BY CLICKING “I ACCEPT” BELOW OR INDICATING YOUR
              ACCEPTANCE IN AN ADJOINING BOX, YOU ARE CONFIRMING THAT YOU
              UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS.
            </p>
            <p>
              IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER
              LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO
              ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU”
              WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF
              YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN AXIOM ZEN IS UNWILLING
              TO MAKE THE SERVICE AVAILABLE TO YOU. YOU ARE RESPONSIBLE TO
              ENSURE THAT ANY USERS THAT USE THE SERVICES ON YOUR BEHALF ARE
              AWARE OF AND COMPLY WITH THESE TERMS AND YOU WILL BE LIABLE FOR
              ANY ACTS, OMISSIONS, USE, MISUSE AND BREACHES BY YOUR USERS.
            </p>
            <p>
              IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE
              SERVICE, OR ANY PART OF IT.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">2. Changes to the Terms</h6>
            <p>
              Axiom Zen may make changes to these Terms from time to time, at
              Axiom Zen’s sole discretion. When Axiom Zen makes changes, it will
              make the updated Terms available on the Site and update the “Last
              Updated” date at the beginning of these Terms accordingly. Please
              check these Terms periodically for changes. Any changes to these
              Terms will apply on the date that they are made, and your
              continued access to or use of the Service after these Terms have
              been updated will constitute your binding acceptance of the
              updates. If you do not agree to any revised Terms, you may not
              access or use the Service.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">3. Changes to the Service</h6>
            <p>
              Axiom Zen aims to constantly innovate the Service to help provide
              the best possible experience. You acknowledge and agree that the
              form and nature of the Service, and any part of it, may change
              from time to time without prior notice to you, and that Axiom Zen
              may add new features and change any part of the Service at any
              time without notice.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">4. Privacy Policy</h6>
            <p>
              <Link onClick={(e) => scrollTo("app", e)} to="/policy">
                Toby’s Privacy Policy
              </Link>{" "}
              describes the ways that Axiom Zen collects, uses, stores and
              discloses your personal information, and is hereby incorporated by
              this reference into these Terms. You acknowledge that the
              collection, use, storage, and disclosure of your personal
              information by Axiom Zen will be done in accordance with the{" "}
              <Link onClick={(e) => scrollTo("app", e)} to="/policy">
                Toby Privacy Policy
              </Link>
              .
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">5. Eligibility</h6>
            <p>
              You represent and warrant that you are fully able and competent to
              accept and comply with these Terms, including all of the
              conditions, obligations, representations and warranties set forth
              herein. By entering into these Terms and accessing the Service or
              any part of it, you represent and warrant that you are at least
              the legal age of majority in your jurisdiction of residence. You
              understand and agree that you are solely responsible for ensuring
              compliance with the laws of your jurisdiction, and acknowledge
              that Axiom Zen is not liable for your compliance (or
              non-compliance) with such laws.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">6. Account Info and Security</h6>
            <p>
              When you set up an account on the Service, you and any users who
              you authorize to access and use the Service through your
              subscription will be asked to provide accurate and complete
              registration information, which may include providing a valid
              email address, as well as choosing applicable usernames and
              passwords (collectively, “Account Info”). You understand that you
              are and will be fully responsible: (i) for the security of your
              and your users’ Account Info, and (ii) for any activity that takes
              place on the Service using your and your users’ Account Info,
              whether you knew about it or not. You agree to inform us
              immediately at{" "}
              <a href="mailto:hello@gettoby.com">hello@gettoby.com</a> if you
              become aware that your or your users’ Account Info has been
              compromised, or used without your permission. Axiom Zen cannot and
              will not be liable for any loss or damage arising from your
              failure to comply with this section.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">7. Payment and Fees</h6>
            <p>
              Access and use of our Service is subject to the payment of the
              applicable fees as set forth on our Plans and Pricing page{" "}
              <a
                href="https://www.gettoby.com/pricing"
                target="_blank"
                rel="noreferrer"
              >
                https://www.gettoby.com/pricing
              </a>
              . You agree to pay the Fees as they become due, which will be
              billed in the intervals that were selected by you during the
              signup process for the Services. Fees are exclusive of any sales,
              use, excise or other taxes, and you agree to pay taxes that are
              applicable to the Fees and your use of the Services. Fees are
              non-refundable. We reserve the right, in addition to any other
              rights or remedies available under these Terms and at law, to
              suspend your access and use of the Services if you do not pay the
              applicable Fees, until such overdue amounts are paid in full. You
              agree to maintain complete, accurate and current billing and
              contact information.
            </p>
            <p>
              Payment for the Service is processed through our third party
              payment processor, Stripe. You acknowledge and agree that: (i)
              payment processing services rendered by Stripe may be subject to
              Stripe’s terms and conditions that you will need to accept in
              order to pays Fees hereunder; and (ii) any claims or issue with
              respect to payment processing shall be taken up with Stripe, and
              we will have no responsibility in respect thereof.{" "}
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">8. Disclaimer of Warranties</h6>
            <p>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF
              THE SERVICE AND ANY PART OF IT IS AT YOUR SOLE RISK, THAT YOU
              ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE SERVICE, AND THAT
              THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
              REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
              APPLICABLE LAW, AXIOM ZEN, ITS PARENTS, SUBSIDIARIES, AFFILIATES,
              AND LICENSORS MAKE NO EXPRESS REPRESENTATIONS, WARRANTIES OR
              CONDITIONS AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING
              THE SERVICE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE
              SITE, THE EXTENSION, OR ANY EXTERNAL WEBSITES THAT YOU MAY ACCESS
              VIA THE SERVICE), INCLUDING ANY IMPLIED WARRANTIES OF
              MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT
              LIMITING THE GENERALITY OF THE FOREGOING, AXIOM ZEN, ITS PARENTS,
              SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR
              WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SERVICE WILL
              MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SERVICE
              WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR OR THAT
              BUGS OR ERRORS WILL BE CORRECTED, (III) USAGE DATA PROVIDED
              THROUGH THE SERVICE WILL BE ACCURATE, (III) THE SERVICE OR ANY
              CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE
              SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV)
              THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SERVICE WILL BE
              SECURE. YOU UNDERSTAND THAT AXIOM ZEN MAKES NO REPRESENTATIONS
              CONCERNING ANY OF THE CONTENT THAT YOU MAY ACCESS VIA THE SERVICE,
              AND THAT AXIOM ZEN WILL NOT BE RESPONSIBLE OR LIABLE FOR THE
              ACCURACY, LEGALITY, OR DECENCY OF MATERIAL CONTAINED IN OR
              ACCESSED VIA THE SERVICE.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">9. Indemnity</h6>
            <p>
              You agree to hold harmless and indemnify Axiom Zen and its
              parents, subsidiaries, affiliates, officers, agents, employees,
              advertisers, licensors, suppliers or partners, as well as the
              officers, directors, employees, shareholders and representatives
              of any of the foregoing entities, from and against any claim,
              liability, loss, damage (actual and consequential) of any kind or
              nature, suit, judgment, litigation cost, and attorneys' fees
              arising out of or in any way related to (i) your or your users’
              breach of these Terms, (ii) your or your users’ use of the
              Service, or any part of it, or (iii) your or your users’ violation
              of applicable laws, rules or regulations in connection with your
              access to or use of the Service or any part of it. Axiom Zen
              reserves the right to assume exclusive defense, control, and
              settlement of any matter for which you are obliged to indemnify us
              under these Terms, and, in such case, you agree to cooperate with
              Axiom Zen (at your own expense) in the defense and/or settlement
              of such matter, at Axiom Zen’s request.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">10. Limitations of liability</h6>
            <p>
              A. YOU UNDERSTAND AND AGREE THAT AXIOM ZEN, ITS PARENTS,
              SUBSIDIARIES, AFFILIATES, AND LICENSORS WILL NOT BE LIABLE TO YOU
              OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER
              CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT
              LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR
              INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF
              DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY
              OTHER TANGIBLE OR INTANGIBLE LOSS, EVEN IF AXIOM ZEN HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
              B. YOU AGREE THAT AXIOM ZEN’S TOTAL, AGGREGATE LIABILITY TO YOU
              FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS
              OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE)
              ANY PORTION OF THE SERVICE, WHETHER IN CONTRACT, TORT, STRICT
              LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF
              (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12
              MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $100.
            </p>
            <p>
              C. YOU ACKNOWLEDGE AND AGREE THAT AXIOM ZEN HAS MADE THE SERVICE
              AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE
              WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH
              HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK
              BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN
              BETWEEN US. AXIOM ZEN WOULD NOT BE ABLE TO PROVIDE THE SERVICE TO
              YOU WITHOUT THESE LIMITATIONS.
            </p>
            <p>
              D. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE
              LIMITATIONS MAY NOT APPLY TO YOU.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">11. Ownership; Restrictions.</h6>
            <p>
              A. You acknowledge and agree that Axiom Zen (or, as applicable,
              its licensors) owns all legal right, title and interest in and to
              all elements of the Service, and the systems and technologies used
              to deliver the Services, including all software, applications,
              materials, formats, interfaces, information, data, content and
              proprietary information and technologies owned or licensed by
              Axiom Zen (collectively, the “Axiom Zen Technology”) and all
              intellectual property rights therein. You acknowledge that the
              Axiom Zen Technology may be protected by copyright, trade dress,
              patent, and trademark laws, international conventions, other
              relevant intellectual property and proprietary rights, and other
              applicable laws. Except as expressly set forth herein, your use of
              the Service does not grant you ownership of or any other rights or
              licenses with respect to any Axiom Zen Technology. Axiom Zen
              reserves all rights in and to the Axiom Zen Technology not
              expressly granted to you in these Terms.
            </p>
            <p>
              B. You may choose to submit comments, bug reports, ideas or other
              feedback about the Service, including without limitation about how
              to improve the Service (collectively, “Feedback”). By submitting
              any Feedback, you agree that Axiom Zen is free to use such
              Feedback at its discretion and without additional compensation to
              you, and to disclose such Feedback to third parties (whether on a
              non-confidential basis, or otherwise). You hereby grant Axiom Zen
              a perpetual, irrevocable, nonexclusive, transferrable,
              sublicensable, worldwide license under all rights necessary for us
              to incorporate and use your Feedback for any purpose.
            </p>
            <p>
              C. You agree that you are responsible for your own conduct while
              accessing or using the Service, and for any consequences thereof.
              You agree to use the Service only for purposes that are legal,
              proper and in accordance with these Terms and any applicable laws
              or regulations. By way of example, and not as a limitation, you
              may not, and may not allow any third party to: (i) send, upload,
              distribute or disseminate any unlawful, defamatory, harassing,
              abusive, fraudulent, obscene, or otherwise objectionable content
              via the Service; (ii) distribute viruses, worms, defects, Trojan
              horses, corrupted files, hoaxes, or any other items of a
              destructive or deceptive nature via the Service; (iii) impersonate
              another person (via the use of an email address or otherwise);
              (iv) upload, post, transmit or otherwise make available through
              the Service any content that infringes the intellectual
              proprietary rights of any party; (v) use the Service to violate
              the legal rights (such as rights of privacy and publicity) of
              others; (vi) engage in, promote, or encourage illegal activity;
              (vii) interfere with other users' enjoyment of the Service; (viii)
              exploit the Service for any unauthorized commercial purpose,
              including selling, leasing, renting, reselling or distributing the
              Service for the benefit of a third party; (ix) modify, adapt,
              translate, create derivative works from, reverse assemble,
              decompile, disassemble, reverse compile or reverse engineer any
              portion of the Service; (x) remove any copyright, trademark or
              other proprietary rights notices contained in or on the Service or
              any part of it; (xi) reformat or frame any portion of the Service;
              (xii) display any content on the Service that contains any
              hate-related or violent content or contains any other material,
              products or services that violate or encourage conduct that would
              violate any criminal laws, any other applicable laws, or any third
              party rights; (xiii) use any robot, spider, site search/retrieval
              application, or other device to retrieve or index any portion of
              the Service, or to collect information about its users for any
              unauthorized purpose; (xiv) create user accounts by automated
              means or under false or fraudulent pretenses; or (xv) access or
              use the Service for the purpose of creating a product or service
              that is competitive with any of Axiom Zen’s products or services.
              Subject to the terms and conditions of these Terms, Axiom Zen
              grants you a non-exclusive, revocable, non-transferable and
              non-sublicensable subscription to access and use the Services for
              internal business purposes.
            </p>
            <p>
              D. You own and are responsible for any data, information or
              information contained in any database, template or other similar
              document that is submitted or provided by you (or on your behalf)
              through the Service or to Axiom Zen (“Your Data”). You grant us a
              non-exclusive, worldwide, royalty-free, transferrable and
              sublicensable right and license to use, reproduce, process, modify
              and display Your Data for the purposes of providing the Services.
              In addition, you grant us this right and license to use Your Data
              on a perpetual basis in an aggregate and anonymized format for
              Axiom Zen’s business purposes, including to develop and improve
              the Services and our other products and services.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">12. Third Party Materials</h6>
            <p>
              The Service may provide links, integrations, add-ons or
              connections to other World Wide Web or accessible sites,
              applications or resources and third-party products and services
              (“Third Party Materials”). Because Axiom Zen has no control over
              such Third Party Materials, you acknowledge and agree that Axiom
              Zen is not responsible for the availability of such Third Party
              Materials, and does not endorse and is not responsible or liable
              to you or to any third party for any content, advertising,
              products or other materials on or available from such sites or
              resources. You further acknowledge and agree that Axiom Zen will
              not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection
              with your use of or reliance upon any such Third Party Materials.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">
              13. Term, Termination and Suspension
            </h6>
            <p>
              The term of these Terms begins upon your subscription to the
              Services for the duration of the subscription term selected by you
              during the registration process (the “Subscription Term”). The
              Subscription Term will renew automatically for subsequent terms
              equivalent in length of the initial Subscription Term, unless as
              cancelled or modified by you using the interface provided on our
              Site.
            </p>
            <p>
              You may terminate these Terms at any time by cancelling your
              account on the Service and discontinuing your access to and use of
              the Service. You will continue to have access to the Services for
              the duration of any prepaid Subscription Term, and these Terms
              shall continue to apply until the end of such Subscription Term.
            </p>
            <p>
              Axiom Zen may immediately and without notice terminate these Terms
              if you materially breach these Terms or if you become insolvent or
              file a petition (or a petition is filed against you) in
              bankruptcy.
            </p>
            <p>
              You will not receive any refunds for any prepaid Subscription Term
              resulting from a cancellation or termination of these Terms.
            </p>
            <p>
              Axiom Zen will not be liable to you or to any third party for any
              such suspension or termination. If Axiom Zen terminates these
              Terms or suspends or terminates your account(s) or access to or
              use of the Service due to your breach of these Terms or any
              suspected fraudulent, abusive, or illegal activity or otherwise
              for material breach, then termination of these Terms will be in
              addition to any other remedies Axiom Zen may have at law or in
              equity.
            </p>
            <p>
              Upon any termination or expiration of these Terms, whether by you
              or by Axiom Zen: (i) your access and use of the Service will
              immediately cease and any rights and licenses granted to you under
              these Terms will terminate; and (ii) you may no longer have access
              to information that you had posted on the Service or that is
              related to your account(s), and you acknowledge that Axiom Zen
              will have no obligation to maintain any such information in its
              databases or to forward any such information to you or to any
              third party. Sections 8, 9, 10, 11, 13, 14 and 15 of these Terms
              will survive the termination or expiration of these Terms for any
              reason.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">
              14. Dispute Resolution; Arbitration
            </h6>
            <p>
              Please read this Section 14 carefully. It requires you to
              arbitrate disputes with Axiom Zen, and limits the manner in which
              you can seek relief from Axiom Zen.
            </p>
            <p>
              All disputes arising out of or in connection with these Terms,
              including without limitation your access or use of the Service,
              the Site, or the Extension, or to any products or services sold or
              distributed through the Service, the Site, or the Extension, will
              be exclusively referred to and finally resolved by arbitration
              under the rules of the Vancouver Arbitration Centre (the
              “VanIAC”). The appointing authority will be the VanIAC. The case
              will be adjudicated by a single arbitrator and will be
              administered by the VanIAC in accordance with its applicable
              rules. Each party will cover its own fees and costs associated
              with the arbitration proceedings. The place of arbitration will be
              Vancouver, British Columbia, Canada. You may choose to have the
              arbitration conducted by telephone, based on written submissions.
              The language of the arbitration will be English. The award of the
              arbitrator will be final and binding, and any judgment on the
              award rendered by the arbitrator may be entered in any court of
              competent jurisdiction. Notwithstanding the foregoing, Axiom Zen
              may seek and obtain injunctive relief in any jurisdiction in any
              court of competent jurisdiction, and you agree that these Terms
              are specifically enforceable by Axiom Zen through injunctive
              relief and other equitable remedies without proof of monetary
              damages.
            </p>
            <p>
              WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE
              TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE
              SERVICE OR ANY PART OF IT: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR
              RIGHT TO HAVE A TRIAL BY JURY; AND (II) YOU HEREBY EXPRESSLY GIVE
              UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS
              IN ANY LAWSUIT, INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS
              INVOLVING ANY SUCH DISPUTE.
            </p>
          </div>
          <div className="g g-gap-1">
            <h6 className="text-neutral-800">15. General</h6>
            <p>
              These Terms constitute the entire legal agreement between you and
              Axiom Zen, govern your access to and use of the Service, the Site,
              and the Extension, and completely replace any prior or
              contemporaneous agreements between the parties related to your
              access to or use of the Service, the Site, and the Extension,
              whether oral or written. There are no third-party beneficiaries to
              these Terms. The parties are independent contractors, and nothing
              in these Terms creates any agency, partnership, or joint venture.
              The language in these Terms will be interpreted as to its fair
              meaning, and not strictly for or against any party. You may not
              assign any of your rights or obligations under these Terms,
              whether by operation of law or otherwise, without our prior
              written consent. Axiom Zen may assign its rights and obligations
              under these Terms in its sole discretion to an affiliate, or in
              connection with an acquisition, sale or merger. Should any part of
              these Terms be held invalid or unenforceable, that portion shall
              be construed consistent with applicable law and the remaining
              portions will remain in full force and effect. Axiom Zen’s failure
              to enforce any provision of these Terms will not be deemed a
              waiver of such provision, nor of the right to enforce such
              provision. These Terms will be governed by and construed in
              accordance with the laws of the province of British Columbia,
              Canada and the federal laws of Canada applicable therein,
              excluding its conflicts of law rules and principles. Subject to
              Section 14, any legal action or proceeding arising under these
              Terms will be brought exclusively in the federal or provincial
              courts located in Vancouver, British Columbia, and the parties
              irrevocably consent to exclusive personal jurisdiction and venue
              there. Axiom Zen will not be liable for any failure or delayed
              performance of its obligations that result from any condition
              beyond its reasonable control, including, but not limited to,
              governmental action, acts of terrorism, earthquake, fire, flood,
              acts of God, labor conditions, power failures, Internet
              disturbances, or acts or omissions of third parties. You agree
              that Axiom Zen may provide you with notices (including, without
              limitation those regarding changes to these Terms) by email,
              regular mail, or postings on the Service, the Site, or the
              Extension. By providing Axiom Zen with your email address, you
              consent to our using the email address to send you any notices
              required by law in lieu of communication by postal mail.
            </p>
          </div>
        </div>
      </Amplitude>
    </div>
  );
};

export default TermsOfServices;
