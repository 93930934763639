import React from 'react';

const SignupCompanyType = ["Consumer Goods", "Financial Services", "Government", "Healthcare/Pharmaceutical", "Media", "Nonprofit", "Retail", "Technology", "Other"];
const SignupCompanySize = ["1-10 people", "11-50 people", "51-100 people" , "101-250 people", "251-1000 people", "More than 1000 people"];
const SignupRole = ["Administrative/Facilities", "Accounting/Finance", "Business Development", "Business Owner", "Customer Support", "Data/Analytics/Business Development", "Design", "Engineering (Software)", "Marketing", "Media/Communications", "Operations", "Product Management", "Program/Project Management", "Research", "Sales", "Other"];
const SignupIsManager = ["No", "Yes"];
const SignupUse = ["Work", "Personal", "School", "Other"];

const SignupCompanyInfo = ({handleSubmit}) => (
  <div id="createCompanyInfo" className="Create-companyInfo Create-flowBlock">
    <form className="Create-maxWidth" onSubmit={handleSubmit}>
      <div>
        <p className="Create-inputLabel">What type of company is it?</p>
        <div className="Form-selectWrapper">
          <select className="Create-companyType Form-input Form-input--dark">
            <option value='' disabled selected>Select one</option>
            {SignupCompanyType.map((name,index)  =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <p className="Create-inputLabel">How many teammates do you have?</p>
        <div className="Form-selectWrapper">
          <select className="Create-companySize Form-input Form-input--dark">
            <option value='' disabled selected>Select one</option>
            {SignupCompanySize.map((name,index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <p className="Create-inputLabel">What is your role?</p>
        <div className="Form-selectWrapper">
          <select className="Create-companyRole Form-input Form-input--dark">
            <option value='' disabled selected>Select one</option>
            {SignupRole.map((name, index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <p className="Create-inputLabel">Are you a manager? (Do people report to you?)</p>
        <div className="Form-selectWrapper">
          <select className="Create-companyManager Form-input Form-input--dark">
            {SignupIsManager.map((name,index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <p className="Create-inputLabel">What will you use Toby for?</p>
        <div className="Form-selectWrapper">
          <select className="Create-companyUse Form-input Form-input--dark">
            {SignupUse.map((name,index) =>  (
              <option key={index} value={name}>{name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="Create-nextButton">
        <button className="Button Button--secondary Button--large" onClick={handleSubmit}>Next</button>
      </div>
    </form>
  </div>
)

export default SignupCompanyInfo;