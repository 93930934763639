import React from 'react';
import { installExtensionSignup } from '../helpers';

const SignupInstall = ({bool}) => {
  bool ? installExtensionSignup() : null
  return (
    <div id="createInstall" className="Create-install Create-flowBlock">
      <div className="Create-maxWidth">
        <div className="Create-addChrome">
          <img alt="chrome-add" src="/img/signup/addChrome.png"/>
        </div>
        <div className="Create-addChromeBtn">
          <button className="Button Button--light Button--large Button--chrome" onClick={installExtensionSignup}>
            <span className="Button--chromeWrapper">
              <img alt="chrome-icon" className="Button--chromeIcon" src="/img/icons/chrome.svg"/>
              <span className="hide--small">Add Toby to Chrome</span>
              <span className="hide--large">Available on Chrome</span>
            </span>
          </button>
        </div>
        <div>
          <p>Great! After installing Toby you're all set. We never look at your web pages and only access basic page metadata so you can organize your tabs on Toby.</p>
          <p>Happy Tobying</p>
        </div>
      </div>
    </div>
  )
}

export default SignupInstall;