import React from 'react';
import {
  Amplitude,
  LogOnMount
} from "@amplitude/react-amplitude";
import {isMobile} from '../constants';

const UninstallThanks = () => {
  return (
    <div className="Header-Margin--offset">
      <div className="Shadow-gradient o-4"></div>
      <Amplitude
        eventProperties={{
          scope: ["Uninstall Thanks"]
        }}>
        <LogOnMount eventType="Uninstall Thanks"/>  
        <div className="g g-row g-gap-2 g-j-i-center p--hor p--top-lg p--bot-xxl maxW--xs t-center uninstall-thanks">
          <img src="/img/logos/logo-symbol-pink.svg" alt="toby" />
          <h6 className="c--brand w--bold-x">We are sorry to see you go.</h6>
          <p className="c--light-black">
            Thank you for your feedback. It will help us make Toby better. We hope you will give us another chance in the future!<br/><br/>
            Curious to stay in the loop as we make Toby better?<br/>Follow us on Twitter <a href="https://twitter.com/tobyfortabs" target="_blank" rel="noopener">@Tobyfortabs</a> or review our <a href="https://feedback.gettoby.com/roadmap" target="_blank" rel="noopener">roadmap</a>.
          </p>
          {!isMobile && <button onClick={() => window.close()}>Close tab</button>}
        </div>
      </Amplitude>
    </div>
  )
}

export default UninstallThanks;