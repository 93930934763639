import React from 'react';
import Invite from './Invite';

const SignupInvite = ({addOneEmailInvite, handleSkip, handleSubmit}) => (
  <form id="createInvite" className="Create-invite Create-flowBlock">
    <div className="Create-maxWidth" handleSubmit={handleSubmit}>
      <p>Invite friends to Toby and unlock an exclusive dark theme. <img alt="invite-friends" src="https://emojipedia-us.s3.amazonaws.com/thumbs/120/emoji-one/104/open-lock_1f513.png" width="16"/></p>
      <div>
         <ul className="Create-inviteEmails">
           <Invite header={true} />
           <Invite/>
           <Invite/>
         </ul>
      </div>
      <div className="Create-skipButton"></div>
      <div className="Create-nextButton">
        <button className="Create-inviteBtn Button Button--secondary Button--large" onClick={handleSubmit}>Invite</button>
        <a className="Create-noInviteBtn" onClick={handleSkip}>I don't want the dark theme</a>
        <a className="Create-inviteMore" onClick={addOneEmailInvite}>Invite more friends</a>
      </div>
    </div>
  </form>
)

export default SignupInvite;