import React from "react";
import FMarquee from "react-fast-marquee";
import clsx from "clsx";

export const UsedByLogos = [
  { src: "img/usedby/google.svg", alt: "Google", className: "py-3.5" }, // normalize height across all logos
  { src: "img/usedby/facebook.svg", alt: "Facebook" },
  { src: "img/usedby/mixpanel.svg", alt: "Mixpanel", className: "py-3.5" },
  { src: "img/usedby/workday.svg", alt: "Workday", className: "py-3" },
  { src: "img/usedby/linkedin.svg", alt: "Linkedin" },
  { src: "img/usedby/uber.svg", alt: "Uber", className: "scale-[1.3]" },
  { src: "img/usedby/intercom.svg", alt: "Intercom", className: "py-3.5" },
  { src: "img/usedby/autodesk.svg", alt: "Autodesk", className: "py-5" },
  { src: "img/usedby/zillow.svg", alt: "Zillow", className: "py-[18px]" },
];

const Marquee = (props) => {
  const { className } = props;
  return (
    <div className={clsx(className)}>
      <FMarquee autoFill>
        <div className="item-center pointer-events-none flex h-14 select-none gap-10 overflow-hidden px-5 md:h-16 md:gap-20 md:px-10">
          {UsedByLogos.map((img) => (
            <img
              key={img.alt}
              src={img.src}
              alt={img.alt}
              className={clsx("h-full", img.className)}
            />
          ))}
        </div>
      </FMarquee>
    </div>
  );
};

export default Marquee;
