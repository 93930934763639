import React from 'react';

const Invite = ({header}) => (
  <li className="Create-inviteSection Grid Grid--spaceBetween">
    <div className="Create-inviteEmail Grid-cell--xs-12 Grid-cell--sm-8">
      {header ? (
        <p className="Create-inputLabel">Email</p>
      ) : null}
      <input className="Form-input Form-input--dark" type="email" name="INVITE" placeholder='name@gmail.com'></input>
    </div>
    <div className="Create-inviteName Grid-cell--xs-12 Grid-cell--sm-4">
      {header ? (
        <p className="Create-inputLabel">Name</p>
      ) : null}
      <input className="Form-input Form-input--dark" type="text" name="NAME" placeholder='Optional'></input>
    </div>
  </li>
)

export default Invite;