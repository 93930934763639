import React from 'react';

const SignupName = ({ name, setName, handleSubmit, isValid }) => (
  <div id="createName" className="Create-name Create-flowBlock">
    <form className="Create-maxWidth" onSubmit={handleSubmit}>
      <p>Your full name</p>
      <div className={isValid ? '' : 'Create-error'}>
        <p className="Create-errorLabel">First and last name required</p>
        <input onChange={e => setName(e.target.value)} value={name} className="js-validate-required js-validate-name js-trigger Form-input Form-input--dark" type="text" name="name" placeholder="First Last"></input>
      </div>
      <div className="Create-nextButton">
        <button className="js-trigger-target Button Button--secondary Button--large" onClick={handleSubmit}>Next</button>
      </div>
    </form>
  </div>
)

export default SignupName;