import React from 'react';

const SignupPassword = ({ password, setPassword, handleSubmit, isValid, selected }) => (
  <div id="createPassword" className="Create-password Create-flowBlock">
    <form className="Create-maxWidth" onSubmit={handleSubmit}>
      <p>Create a password</p>
      <div className={isValid ? '' : 'Create-error'}>
        <p className="Create-errorLabel">Password must be at least 8 characters</p>
        <input autoFocus={selected ? 'autofocus' : ''} onChange={e => setPassword(e.target.value)} value={password} className="js-validate-required js-validate-password js-trigger Form-input Form-input--dark" type="password" name="password" placeholder="password"></input>
        <input className="Form-input Form-input--dark" type="text" name="__toby_security__" placeholder="password" style={{'display': 'none'}}></input>
      </div>
      <div className="Create-passwordPolicy">
        <small className="t--dimed">By creating an account, you agree to our <a href="/privacy" target='_blank'> privacy policy.</a></small>
      </div>
      <div className="Create-nextButton">
        <button className="js-trigger-target Button Button--secondary Button--large" onClick={handleSubmit}>Create Account</button>
      </div>
    </form>
  </div>
)

export default SignupPassword;